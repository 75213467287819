<template>
<div>
	<div id="warp">

		<mark v-for="(item,index) in arr">
			<span>{{item.name}}</span><a style="color: red;" href="javascript:;" @click="remove(index,item.id)">X</a>
		</mark>
		<!-- <span v-for="(item,index) in arr">{{item.name}}</span> -->
		<div>
			<ul>
				<li v-for="(item,index) in phoneData">
        <div style="margin-left:40px">
					<span style="margin-right:70px">{{item.title}}:</span>

					<a href="javascript:;" v-for="option,i in item.list" v-bind:class="{active:item.index===i}" @click="listBundle(option,index,i)">{{option}}
					</a>
          </div>
				</li>
			</ul>

		</div>



	</div>


<div style="		width: 71%;
		margin: 20px auto;text-align:center;">
<el-row >
  <el-col :span="6">
	  <div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div ><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806030243990.jpg"></div>
											<div class="name">左益 苯磺酸左氨氯地平片</div>
											<div class="desc">5mg*14片</div>
											<!-- <div class="price">31.50元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806030834707.jpg"></div>
											<div class="name">施慧达 苯磺酸左氨氯地平片</div>
											<div class="desc">2.5mg*7片*2板</div>
<!--											<div class="price">30.00元</div>-->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806030926222.jpg"></div>
											<div class="name">百普乐 培哚普利吲达帕胺片</div>
											<div class="desc">20片</div>
											<!-- <div class="price">79.00元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031031442.jpg"></div>
											<div class="name">拜新同 硝苯地平控释片</div>
											<div class="desc">30mg*7片</div>
											<!-- <div class="price">33.00元</div> -->

									</div>
    </el-col>
      <el-col :span="6">
		  <div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div ><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031153143.jpg"></div>
											<div class="name">阿尔马尔 盐酸阿罗洛尔片</div>
											<div class="desc">10mg*10片</div>
											<!-- <div class="price">34.80元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031220847.jpg"></div>
											<div class="name">喜来乐 复方杜仲片(杜仲降压片)</div>
											<div class="desc">0.32g*90片/瓶</div>
											<!-- <div class="price">17.00元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031254877.jpg"></div>
											<div class="name">瑙珍 复方天麻蜜环糖肽片</div>
											<div class="desc">0.25g*12片*4板</div>
											<!-- <div class="price">36.50元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031328329.jpg"></div>
											<div class="name">伊迈格 托拉塞米片</div>
											<div class="desc">5mg*14片</div>
											<!-- <div class="price">11.50元</div> -->

									</div>
    </el-col>
      <el-col :span="6">
		  <div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div ><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031404397.jpg"></div>
											<div class="name">多达一 氨氯地平阿托伐他汀钙片</div>
											<div class="desc">(5mg/10mg)*7片</div>
											<!-- <div class="price">47.00元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031440721.jpg"></div>
											<div class="name">静瑞欣 苯磺酸左氨氯地平片</div>
											<div class="desc">2.5mg*7片*2板/盒</div>
											<!-- <div class="price">19.00元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031530720.jpg"></div>
											<div class="name">以岭 芪苈强心胶囊</div>
											<div class="desc">0.3g*36粒</div>
											<!-- <div class="price">34.80元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031603657.jpg"></div>
											<div class="name">傲坦 奥美沙坦酯片</div>
											<div class="desc">20mg*7片</div>
											<!-- <div class="price">43.80元</div> -->

									</div>
    </el-col>
      <el-col :span="6">
		  <div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031646578.jpg"></div>
											<div class="name">倍他乐克 琥珀酸美托洛尔缓释片</div>
											<div class="desc">47.5mg*7片</div>
											<!-- <div class="price">18.30元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
		<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806031814598.jpg"></div>
											<div class="name">吉加 厄贝沙坦片</div>
											<div class="desc">0.15g*7片</div>
											<!-- <div class="price">8.00元</div> -->

									</div>
    </el-col>
    <el-col :span="6">
									<div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806032558825.jpg"></div>
											<div class="name">倍博特 缬沙坦氨氯地平片(Ι)</div>
											<div class="desc">80mg/5mg*7片【进口正品】</div>
											<!-- <div class="price">59.90元</div> -->

									</div>
    </el-col>
     <el-col :span="6">
		 <div class="row shoppingDetail" @click="goToGoodsDetail()">

											<div><img class="medicalImg" src="http://81.70.76.82:8099/upload/20210806/20210806032642728.jpg"></div>
											<div class="name">施美力健 苯磺酸左氨氯地平片</div>
											<div class="desc">2.5mg*7片*2板</div>
											<!-- <div class="price">19.80元</div> -->

									</div>
    </el-col>

  </el-row>


<div>
  <div style="height:60px">
      <el-pagination
  style="float: right;margin-top:20px"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
    </div>
    </div>
</div>



  </div>


</template>

<script>
	export default {
		data() {
			return {
                currentPage4: 4,
				phoneData: [{
						title: '分类',
						list: ['高血压类', '高血脂类', '冠心病类']
					},
					{
						title: '价格',
						list: ['不限', '0-15', '16-39', '40-59', '60-79']

					},
					{
						title: '排序',
						list: ['销量', '评分', '价格', '最新上架']
					},
				],
				// listData: this.phoneData,
				arr: []
			}
		},
		methods: {
			listBundle(option, index, i) {
				//找到操作的这一行，把这一行的数据中的index，设置为点击的标签的下标
				this.phoneData[index].index = i;
				let param = { //获取到元素的index 和 name
					id: index,
					name: option
				}
				this.arr.push(param) //将获取的元素放到数组里面
				// 用户点击重复的之后去除重复的数据 保证只存在一个相同的id
				for (let i = 0; i < this.arr.length; i++) {
					for (let j = i + 1; j < this.arr.length; j++) {
						if (this.arr[i]['id'] == this.arr[j]['id']) { //判断是否存在重复的
							// 将最后点击的id的name 赋值给现有的id里面的name
							this.arr[i]["name"] = this.arr[j]['name']
							this.arr.splice(j, 1) // 移除数据里面重复的
						}
					}
				}
			},
			remove(index, id) {
				this.$delete(this.arr, index, 1); //删除arr里面用户点击的id
				this.phoneData[id].index = -1;
			},
			goToGoodsDetail(){
				this.$emit('changeType',13)
			}
		}
	}
</script>

<style scoped="scoped">
	#warp {
		width: 70%;
		margin: 20px auto;
		background-color: #F7F7F7;
    color:#555;
    height: 165px;
	}

	mark {
		width: 100%;
		height: 30px;
		border: 1px solid pink;
	}

	li {
		list-style: none;
		float: left;
		width: 100%;
		height: 55px;
    line-height:35px;
		border-bottom: solid 1px white;
	}

  	li:last-child {
		border-bottom: solid 0px white;
	}

	#warp div {
		width: 100%;
		height: 100%;
	}

	a {
		display: inline-block;
		margin: 10px 20px 0 0;
		text-decoration: none;
	}

	.active {
		background-color: red;
	}

.row{
  background-color:#F7F7F7;
  margin:10px
}

 .row img{margin:0 auto 24px auto; padding-top:20px}

  .row:hover{ box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)}
.name{height:26px;}
 .desc{height: 32px;color:#B0B0B0;}
.price{color:orange;padding-bottom: 10px}

	.medicalImg{
		width: 190px;
		height: 170px;
	}

	.shoppingDetail{
		cursor:pointer;
	}
  @import '../../common/styles/style.css';
</style>
