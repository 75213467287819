<template>
   <div style="	width: 73%;
		margin: 20px auto;">


<el-row :gutter="20">

  <el-col :span="19">


<el-row :gutter="20">

  <el-col :span="10">

<div style=" border: 1px solid red;border: 1px solid #e5e5e5;">
<img :src="detail.leftImg"  style="width:380px;height:380px"/>


<div style=" border: 1px solid red;border: 1px solid #e5e5e5;width:70px;height:70px; position: relative;

  left: 38%; bottom: 10px;

  ">
<img :src="detail.leftImg" style="width:70px;height:70px; "/>
</div>
</div>
  </el-col>

    <el-col :span="14">


<div style="    padding: 5px 0;
    color: #222;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;">

{{detail.name}} {{detail.specs}}
</div>

<div style="height:65px;line-height: 55px; color: #555; border-bottom: 1px dashed #e5e5e5;">

<el-row>

  <el-col :span="12">
商品编号 ：{{detail.goodsNumber}}
  </el-col>

    <el-col :span="12">
库存 ：现货 20
  </el-col>
  </el-row>
</div>


<div style="height:65px;line-height: 55px; color: #555; border-bottom: 1px dashed #e5e5e5;">

<el-row>

<!--  <el-col :span="12">
销量 ：0
  </el-col> -->

    <el-col :span="12">

<el-row>

  <el-col :span="6">
评分 ：</el-col>
<el-col :span="18">
<el-rate
style="margin-top:16px"
  v-model="value"
  disabled
  show-score
  text-color="#ff9900"
  score-template="{value}">
</el-rate>
</el-col>
</el-row>
  </el-col>
  </el-row>
</div>




<!-- <div style="height:65px;line-height: 55px; color: #555; border-bottom: 1px dashed #e5e5e5;">

<el-row>

  <el-col :span="24">
销售价 ：<span style="color: #e01d20;;font-size:24px;font-weight: bold;vertical-align:bottom">￥{{detail.price}}</span>
  </el-col>

  </el-row>
</div> -->



<!-- <div style="padding-top:10px;padding-bottom:10px; color: #555; border-bottom: 1px dashed #e5e5e5;">

运费：至 浙江： 快递：<span style="color:darkorange;font-weight: bold;;">￥20</span>（直接由第三方物流公司配送）  货到付款：<span style="color:darkorange;font-weight: bold;;">￥20</span>（直接由第三方物流公司配送）
</div> -->


<!-- <div style="color: #555; margin-top:20px">
   购买数量 ： <el-input-number size="small" v-model="num"  :min="1" :max="10" label="描述文字"></el-input-number>
  </div> -->


<!-- <div style=" margin-top:30px">
 <el-button type="primary" icon="el-icon-shopping-bag-2" plain>立即购买</el-button>
  <el-button type="primary" icon="el-icon-shopping-cart-2" @click="addCart()">加入购物车</el-button>
  </div> -->


  </el-col>
  </el-row>



<el-tabs type="border-card" style="margin-top:20px" v-model="activeName">
  <el-tab-pane label="商品详情" name="1">


<div class="detail">
<el-row>
  <el-col :span="12">
商品名称：{{detail.name}}
  </el-col>
    <el-col :span="12">
规格：{{detail.specs}}
  </el-col>
  </el-row>
</div>


<div class="detail">
<el-row>
  <el-col :span="12">
生产企业：{{detail.company}}
  </el-col>
    <el-col :span="12">
通用名称：{{detail.commonName}}
  </el-col>
  </el-row>
</div>

<div class="detail">
<el-row>
  <el-col :span="12">
温馨提示：依据《药品经营质量管理规范》，除药品质量原因外，药品一经售出，不得退换。
  </el-col>
    <el-col :span="12">
有效期：30个月
  </el-col>
  </el-row>
</div>

<div class="detail">
<el-row>
  <el-col :span="12">
批准文号：{{detail.approvalNumber}}
  </el-col>
    <el-col :span="12">
适应症/功能主治：{{detail.indication?detail.indication:'暂无'}}
  </el-col>
  </el-row>
</div>

<div class="detail">
<el-row>
  <el-col :span="12">
用法用量：{{detail.dosage?detail.dosage:'暂无'}}
  </el-col>
    <el-col :span="12">
不良反应：尚不明确。
  </el-col>
  </el-row>
</div>

      <div class="detail" v-show="detail.certificateNumber">
          <el-row>
              <el-col :span="24">
                  二类备案凭证编号：{{detail.certificateNumber}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.companyName&&detail.department">
          <el-row>
              <el-col :span="12">
                  企业名称：{{detail.companyName}}
              </el-col>
              <el-col :span="12">
                  发证部门：{{detail.department}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.person1&&detail.person1">
          <el-row>
              <el-col :span="12">
                  法定代表人：{{detail.person1}}
              </el-col>
              <el-col :span="12">
                  企业负责人：{{detail.person2}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.licenceNumber">
          <el-row>
              <el-col :span="24">
                  许可证编号：{{detail.licenceNumber}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.address">
          <el-row>
              <el-col :span="24">
                  生产地址：{{detail.address}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.productionScope">
          <el-row>
              <el-col :span="24">
                  生产范围：{{detail.productionScope}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.residence">
          <el-row>
              <el-col :span="24">
                  住所：{{detail.residence}}
              </el-col>
          </el-row>
      </div>

      <div class="detail" v-show="detail.validPeriod&&detail.issueDate">
          <el-row>
              <el-col :span="12">
                  有效期限：{{detail.validPeriod}}
              </el-col>
              <el-col :span="12">
                  发证日期：{{detail.issueDate}}
              </el-col>
          </el-row>
      </div>

    <el-image :src="detail.detailImg" style="width: 100%;"  fit="cover"></el-image>
</el-tab-pane>
    <el-tab-pane label="注册证" name="2" :disabled="!detail.registerImg">
        <el-image :src="detail.registerImg" style="width: 100%;"  fit="cover"></el-image>
    </el-tab-pane>
    <el-tab-pane label="生产许可证" name="3" :disabled="!detail.permitImg">
        <el-image :src="detail.permitImg" style="width: 100%;"  fit="cover"></el-image>
    </el-tab-pane>
    <el-tab-pane label="二类备案证明" name="4" :disabled="!detail.certificateImg">
        <el-image :src="detail.certificateImg" style="width: 100%;"  fit="cover"></el-image>
    </el-tab-pane>
</el-tabs>


  </el-col>

    <el-col :span="5">


<div style="border: 1px solid #e5e5e5; text-align: center; ">

<div  style="position: relative;

  left: 12%; bottom: 10px;">
  <img style="height:166px" src="http://81.70.76.82:8099/upload/seller/20210806024757564.png" />
</div>
<p style="  color: #3a3c84;
    position: relative;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;">
<!--  洱全药品-->
</p>

<div style="padding: 15px; border-bottom: 1px dashed #e5e5e5;">
<!--  <el-button type="primary" size="mini" @click="toDetail()">进入店铺</el-button>-->

  </div>

<div style="height:60px;line-height:60px;font-size:16px;color: #777;">
<!--  综合评价 : 5-->
  </div>

  <div  style="color:#e16070; border-bottom: 1px dashed #e5e5e5; padding-bottom:20px">

    评价：5
服务：5
履约：5

    </div>

    <div style="height:60px;line-height:60px;color: #777;border-bottom: 1px dashed #e5e5e5;">
      店铺地址：河北省 保定市 涞水县
      </div>

    <div style="height:60px;line-height:60px;color: #777;    font-size: 18px;">
      热销商品
      </div>

      <div style="margin: 10px; border-bottom: 1px dashed #e5e5e5; padding-bottom:10px">


     <el-row>
      <el-col :span="6">
     <img class="shoppingDetail" @click="goToGoodsDetail" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1NDU0MzU5LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"/>

        </el-col>

             <el-col :span="18">
             <div style="color:#555">氟伐他汀钠胶囊 40mg*7粒</div>
             <br/>
             <!-- <p style="color:red">￥27.99</p> -->
        </el-col>
      </el-row>


        </div>


  <div style="margin: 10px; border-bottom: 1px dashed #e5e5e5; padding-bottom:10px">


     <el-row>
      <el-col :span="6">
     <img class="shoppingDetail" @click="goToGoodsDetail" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQxMjQyNjM5LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"/>

        </el-col>

             <el-col :span="18">
             <div style="color:#555">天士力 复方丹参滴丸 27mg*180丸</div>
             <br/>
             <!-- <p style="color:red">￥27.99</p> -->
        </el-col>
      </el-row>


        </div>

          <div style="margin: 10px; border-bottom: 1px dashed #e5e5e5; padding-bottom:10px">


     <el-row>
      <el-col :span="6">
     <img class="shoppingDetail" @click="goToGoodsDetail" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM0OTIzNzM4LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"/>

        </el-col>

             <el-col :span="18">
             <div style="color:#555">富利他之 普伐他汀钠片 20mg*10片</div>
             <br/>
             <!-- <p style="color:red">￥38.99</p> -->
        </el-col>
      </el-row>


        </div>

           <div style="margin: 10px; border-bottom: 1px dashed #e5e5e5; padding-bottom:10px">


     <el-row>
      <el-col :span="6">
     <img class="shoppingDetail" @click="goToGoodsDetail" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1MTQ0OTEzLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"/>

        </el-col>

             <el-col :span="18">
             <div style="color:#555">汉王 强力定眩片 0.35g*60片</div>
             <br/>
             <!-- <p style="color:red">￥12.99</p> -->
        </el-col>
      </el-row>


        </div>

           <div style="margin: 10px; padding-bottom:10px">


     <el-row>
      <el-col :span="6">
     <img class="shoppingDetail" @click="goToGoodsDetail" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQ0NDExNTUzLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"/>

        </el-col>

             <el-col :span="18">
             <div style="color:#555">甘复生 壳脂胶囊 0.25g*30粒</div>
             <br/>
             <!-- <p style="color:red">￥76.99</p> -->
        </el-col>
      </el-row>


        </div>

  </div>

  </el-col>
  </el-row>


    </div>
</template>
<script>

export default {
    props:{
        id:{
            type:String
        }
    },
    data() {
        return {
   value: 3.7,
   num:"",
            detail:{},
            activeName: '1',
            temp:[
                {
                    id:"1",
                    name:"同济堂 润燥止痒胶囊",
                    commonName:"润燥止痒胶囊",
                    price:"22.00",
                    specs:" 0.5g*24粒",
                    count:1,
                    company:"国药集团同济堂(贵州)制药有限公司",
                    approvalNumber:"国药准字Z20025030",
                    goodsNumber:"Z20025030",
                    dosage:"口服，一次4粒，一日3次，2周为一疗程。",
                    indication:"苗医：怡象任早，墟瘕任者，滇劫挡祛卡，任哈赊嘎；雪皮风症。中医：养血滋阴，祛风止痒，润肠通便。用于血虚风燥所致的皮肤瘙痒，痤疮，便秘。",
                    leftImg:"http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDkxMjM1MjM1LmpwZy93LzM4MC9oLzM4MAO0O0OO0O0O",
                    detailImg:require("../../assets/detail.png")
                },
                {
                    id:"2",
                    name:"肺活量计",
                    commonName: "肺活量计",
                    price:"1980",
                    specs: "A1",
                     count:1,
                    company: "广州红象医疗科技有限公司",
                    approvalNumber:"粤械注准20172070734",
                    goodsNumber:"20172070734",
                    dosage:"",
                    indication: "",
                    leftImg:require("../../assets/medical/medical1.png"),
                    detailImg:require("../../assets/detail2.png"),
                    registerImg:require("../../assets/detail/zhuce1.png"),
                    permitImg:require("../../assets/detail/xuke1.png"),
                    certificateImg:require("../../assets/detail/pingzheng1.png"),
                    certificateNumber:"浙杭药监械经营备20222322号",
                    companyName:"广州红象医疗科技有限公司",
                    department:"广东省药品监督管理局",
                    person1:"黄穗杰",
                    person2:"文瑜龙",
                    licenceNumber:"粤食药监械生产许20173025号",
                    address:"广州市海珠区荔福路新围28号7楼自编02号",
                    productionScope:"II类07医用诊察和监护器械-02呼吸功能及气体分析测定装置",
                    residence:"广州市海珠区荔福路新围28号7楼自编02房",
                    validPeriod:"至2027年03月10日",
                    issueDate:"2022年03月21日"
                },
                {
                    id:"3",
                    name:"呼吸过滤器",
                    commonName: "呼吸过滤器 Bacterial/Viral Filter",
                    price:"25",
                     count:1,
                    specs: "MicroGard IIB",
                    company: "伟亚安医疗器械公司 Vyaire Medical GmbH",
                    approvalNumber:"国械注进20152082110",
                    goodsNumber:"20152082110",
                    dosage:"",
                    indication: "",
                    leftImg:require("../../assets/medical/medical2.jpg"),
                    detailImg:require("../../assets/detail3.png"),
                    registerImg:require("../../assets/detail/zhuce2.png"),
                },
                {
                    id:"4",
                    name:"压电式雾化器",
                    commonName: "压电式雾化器",
                    price:"1200",
                    specs: "HL100F",
                     count:1,
                    company: "合泰医疗电子（苏州）有限公司",
                    approvalNumber:"苏械注准20142080301",
                    goodsNumber:"20142080301",
                    dosage:"",
                    indication: "",
                    leftImg:require("../../assets/medical/medical3.jpg"),
                    detailImg:require("../../assets/medical/medical3.jpg"),
                    registerImg:require("../../assets/detail/zhuce3.png"),
                    permitImg:require("../../assets/detail/xuke3.png")
                }
            ]
        };
    },
    mounted(){
        this.changeDetail(this.id)

    },
    computed:{
        application(){
            return this.id
        }
    },
    watch: {
        application: function (val) {
            console.log(val)
            this.changeDetail(this.id)
            this.activeName = '1'
        },
    },
    methods: {
        //进入商家
        toDetail(){
            this.$emit('changeType',8)
        },
        goToGoodsDetail(){
            this.$emit('changeType',13)
        },
        changeDetail(id){
            let idTemp = id
            if(idTemp === undefined){
                idTemp = '1'
            }
            this.temp.forEach(item=>{
                if(item.id === idTemp){
                    this.detail = item
                }
            })
            console.log(this.detail)
        },

        addCart(){
      this.$emit('changeType3',this.detail,11)

        }
    }
};
</script>
<style scoped>

.detail{
   color: #555; border-bottom: 1px dashed #e5e5e5;
   padding:10px
}
    .shoppingDetail{
        cursor:pointer;
    }
</style>
