<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" class="login-form">
      <h3 class="title">账号登录</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="填写用户名或邮箱">
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="填写密码">
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <div style="display: flex">
        <el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 25px 0px">记住登录名</el-checkbox>
        <div class="loginDiv">
          <i class="el-icon-s-claim"></i><span @click="toResetPassword()" class="loginSpan">忘记密码</span>
        </div>
      </div>
      <el-form-item style="width: 100%">
        <el-button size="medium" type="primary" style="width: 100%">
          <span>登 录</span>
        </el-button>
      </el-form-item>
      <div style="display: flex">
        <div class="loginDiv">没有账号？<span class="loginSpan" @click="toRegister()">立即注册</span></div>
      </div>
    </el-form>
  </div>

</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        loginForm:{}
      }
    },
    methods: {
      //跳转忘记密码页
      toResetPassword(){
        this.$emit('changeType',4)
      },
      //跳转注册页面
      toRegister(){
        this.$emit('changeType',3)
      }
    }
  }
</script>

<style lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("@/assets/loginBg.jpg");
    background-size: cover;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 21px;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    height: 400px;
    padding: 25px 25px 5px 25px;
    margin-left: 50%;
    margin-top: 5%;
    margin-bottom: 5%;

    .el-input {
      height: 38px;
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 38px;
  }

  .loginDiv{
    font-size: 14px;
    margin-left: 10px;
    margin-top: -2px;
  }

  .loginSpan{
    margin-left: 10px;
    cursor:pointer;
  }
</style>
