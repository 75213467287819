<template>
  <div>
    <div class="background"></div>
    <div class="background background1"></div>
    <div class="addFormIn" @click="toAdd()">
      申请入驻
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        loginForm:{},
        type:false
      }
    },
    methods: {
      //跳转申请入驻页
      toAdd(){
        this.$emit('changeType',6)
      }
    }
  }
</script>

<style lang="scss">
  .background{
    height: 400px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("../../../assets/settle.png");
  }

  .background1{
    background-image: url("../../../assets/settle_process.png");
  }

  .addFormIn{
    width: 30%;
    margin-left:35%;
    text-align: center;
    background-color: dodgerblue;
    height: 40px;
    color: white;
    font-size: 17px;
    padding-top: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor:pointer;
  }
</style>
