<template>
  <div>
    <div style="width: 90%;margin-left: 5%">
      <el-row style="width: 100%">
        <el-col :span="4">
          <el-menu
            @open="handleOpen"
            @close="handleClose">
            <el-submenu index="1">
              <template slot="title">
                <span>联系客服</span>
              </template>
<!--              <el-menu-item-group>-->
<!--                <el-menu-item index="1-1" @click="changeType('1-1')">用户服务协议</el-menu-item>-->
<!--                &lt;!&ndash; <el-menu-item index="1-2" @click="changeType('1-2')">购物流程</el-menu-item> &ndash;&gt;-->
<!--                <el-menu-item index="1-3" @click="changeType('1-3')">会员制度</el-menu-item>-->
<!--                <el-menu-item index="1-4" @click="changeType('1-4')">积分说明</el-menu-item>-->
<!--                &lt;!&ndash; <el-menu-item index="1-5" @click="changeType('1-5')">交易条款</el-menu-item> &ndash;&gt;-->
<!--                <el-menu-item index="1-6" @click="changeType('1-6')">订单状态</el-menu-item>-->
<!--              </el-menu-item-group>-->
            </el-submenu>
<!--            <el-submenu index="2">
             <template slot="title">
                <span>支付帮助</span>
              </template>
             <el-menu-item-group>
                <el-menu-item index="2-1" @click="changeType('2-1')">货到付款</el-menu-item>
                <el-menu-item index="2-2" @click="changeType('2-2')">在线支付</el-menu-item>
                <el-menu-item index="2-3" @click="changeType('2-3')">余额支付</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
<!--            <el-submenu index="3">
              <template slot="title">
                <span>配送帮助</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="3-1" @click="changeType('3-1')">加急快递</el-menu-item>
                <el-menu-item index="3-2" @click="changeType('3-2')">商品验货与签收</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
<!--            <el-submenu index="4">-->
<!--              <template slot="title">-->
<!--                <span>售后服务</span>-->
<!--              </template>-->
<!--              <el-menu-item-group>-->
<!--                <el-menu-item index="4-1" @click="changeType('4-1')">换货说明</el-menu-item>-->
<!--                <el-menu-item index="4-2" @click="changeType('4-2')">退货说明</el-menu-item>-->
<!--                <el-menu-item index="4-3" @click="changeType('4-3')">退/换货注意事项</el-menu-item>-->
<!--                &lt;!&ndash; <el-menu-item index="4-4" @click="changeType('4-4')">余额的使用与提现</el-menu-item> &ndash;&gt;-->
<!--                <el-menu-item index="4-5" @click="changeType('4-5')">售后服务</el-menu-item>-->
<!--              </el-menu-item-group>-->
<!--            </el-submenu>-->
<!--            <el-submenu index="5">-->
<!--              <template slot="title">-->
<!--                <span>帮助信息</span>-->
<!--              </template>-->
<!--              <el-menu-item-group>-->
<!--                <el-menu-item index="5-1" @click="changeType('5-1')">关于我们</el-menu-item>-->
<!--                <el-menu-item index="5-2" @click="changeType('5-2')">常见问题</el-menu-item>-->
<!--                <el-menu-item index="5-3" @click="changeType('5-3')">找回密码</el-menu-item>-->
<!--                <el-menu-item index="5-4" @click="changeType('5-4')">退订邮件/短信</el-menu-item>-->
<!--                <el-menu-item index="5-5" @click="changeType('5-5')">联系客服</el-menu-item>-->
<!--              </el-menu-item-group>-->
<!--            </el-submenu>-->
          </el-menu>
        </el-col>
        <el-col :span="20">
          <div v-show="index === '1-1'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              用户服务协议{{parentIndex}}
            </div>
            <div class="contentDiv">
              本公司在此特别提醒用户认真阅读、充分理解本协议各条款,特别是其中所涉及的免除、限制本公司责任的条款、对用户权利限制条款、争议解决和法律适用等。请用户审慎阅读并选择接受或不接受本协议(未成年人应在监护人陪同下阅读)。除非用户接受本协议所有条款,否则用户无权使用本公司于本协议下所提供的服务。用户在本网站注册、登录或使用本网站服务,即表示用户完全接受本协议的全部条款,并同意接受其约束。本协议适用于网站的用户(以下简称"用户"、"您"),若您使用网站(包括但不限于以任何形式接通、进入、浏览网站,或者使用网站的各项服务、功能等),您的使用行为将被视为对本用户协议全部内容的知晓、认可并同意遵守。
              <br><br>
              1、接受
              <br><br>
              1.1当用户您使用网站时,您已经完全知晓、认可并同意遵守本协议中的条款选择访问或使用本网站有关服务,将视为同意接受本协议全部条款的约束。。
              <br><br>
              1.2网站有权在必要时更新本协议。网站保留对网站结构、服务、功能等进行调整的权利。除非特别声明,新功能、新服务将适用本协议。无论本协议的每次更新还是服务内容调整,都会在网站即刻发布,无需另行通知您并立即生效。如果您拒绝接受新的协议或者服务,您必须立即放弃使用网站;若您继续使用网站,则表明您已经完全知晓、认可并同意遵守新的协议或者服务除非另有明确规定,本网站所推出的新产品、新功能和新服务,均无条件的适用本协议。本网站保留在任何时候修改本协议条款的权利,且无需另行通知。用户在使用服务时应关注并遵守
              <br><br>
              2、用户
              <br><br>
              2.1符合下列条件之一的个人、组织才能成为本网站的用户:
              <br><br>
              2.1.1是在中国大陆(含香港地区)依法设立、有效存续且产品合法的自然人、法人及其他组织。若您不具备此资格,请不要使用网站。用户应当具备使用本网站和/或在本购物网购买商品、享受服务的完全民事行为能力,并承诺对相关行为承担相应的法律责任。
              <br><br>
              2.2不具备上述资格的人不得成为本网站用户。本公司一经发现,有权立即注销该用户并网站有权禁止被注销取消用户资格的自然人、法人及其他组织再次使用网站。
              <br><br>
              3、注册条款
              <br><br>
              3.1若您在使用网站的过程中注册成为会员,您必须同意用户可以通过在本网站免费注册帐号使用本网站提供的各项服务,注册时应根据:本
              <br><br>
              申请会员时,根据网站的要求,提供真实、准确、即时、完整的个人信息,包括但不限于您的姓名或名称、联系地址、联系电话、电子邮箱地址等;。如因注册信息不真实而引起的问题由用户本人承担,本网站不负任何责任并有权暂停或终止用户的帐号。
              <br><br>
              3.2 注册成功后,用户将获得帐号及其密码,作为您在本网站交易及享受服务时
              <br><br>
              唯一的身份标识。用户需自行妥善保管账户和密码,不得将自身帐号转让、转借给其他第三人使用,对该帐号及其密码负保管义务,并对由此引起的纠纷、损失和法律责任担责。
              <br><br>
              3.2如用户代表其他自然人、法人或其他组织在网站上注册会员,则需声明并保证已获得授权并有权使前述法律主体受本协议的约束。
              <br><br>
              4、使用条款
              <br><br>
              4.1使用本网站时,用户须遵守国家的法律法规和规章制度,遵守网站有关协议、规定、规则、程序和惯例,不得利用网站从事任何非法或不正当活动。用户不得在本网站发布各类违法或违规信息,包括但不限于商品信息、交易信息、社区帖子、商品留言,店铺留言,评价内容等
              <br><br>
              4.2用户确提供的注册信息等资料系真实、准确、完整、合法,该资料包括但不限于真实姓名或名称、身份证号、联系电话、地址、邮政编码等;用户保证本网站及其他第三方可以通过上述联系方式与自己进行联系;用户也应在相关资料实际变更时,予以及时更新。本公司对用户提供的信息予以保密。
              <br><br>
              4.3用户有权根据本协议的规定以及网站发布的相关规则在本网站上查询商品信息、发表使用体验、参与商品讨论、邀请关注好友、上传商品图片、参加各类活动,以及享受本提供的其它服务。
              <br><br>
              4.4用户有义务在注册及享受服务、购买商品时提供真实、详尽、准确的资料/信息(包括但不限于电子邮件地址、联系电话、联系地址、邮政编码等),并保证资料/信息的及时、有效更新,使得网站可以通过上述联系方式与用户本人进行联系。
              <br><br>
              4.5网站的任何通知都将以电子邮件形式发送至用户的电子邮箱(您同意,如您修改原注册时提交的电子邮箱地址、并通过网站审核的,以新电子邮箱地址为准)。该电子邮件进入前述电子邮箱接收系统时,视为送达。
              <br><br>
              4.6网站有权审核、发布或删除会员提交的信息。网站将尽力核查会员提交的信息,但并不能完全保证信息的真实性、准确性和合法性,同时也不承担由此引至的任何法律责任。
              <br><br>
              4.7网站含有与其他网站的链接。但并不能控制这些网站及其所提供的资源,对链接网站上的内容、广告、服务、产品信息的真实、准确、即时、完整性不做任何承诺,并且对链接网站不承担任何法律责任。
              <br><br>
              4.8用户同意,对于其上传到本网站的任何内容(包括但不限于文字、图片、视频),网站具有免费的、永久的、不可撤销的、非独家的和完全的许可和再许可的权利,以使用、复制、修改、改编、出版、翻译、据以创作衍生作品,传播、表演和展示前述内容(整体或部分),和/或将前述内容编入其他任何形式的作品、媒体、或技术中,不论是当前已知的或以后开发的。同时,用户同意网站在未改变其提供信息的实质内容的情况下免于承担因该信息引起的任何法律责任。
              <br><br>
              4.9若用户违反本协议规定,或有违反国家及地方法律法规之行为,本商城有权停止传输并删除相关信息,屏蔽/禁止用户的发表、使用,注销账户,如有必要可依法向相关主管部门进行披露。
              <br><br>
              5、商品交易流程及相关
              <br><br>
              5.1用户同意遵守本网站实行的“购物指南”约定。
              <br><br>
              5.2本网站有权在发现其网站上显现的商品和/或订单有明显错误或缺货的情况下,单方面撤回或修改该等信息。
              <br><br>
              5.3购物网保留对商品订购的数量的限制权,包括但不限于有权对每一账户最高购买数量及商品总数量进行限制与修改。
              <br><br>
              5.4购物网对商品自主定价(价目表并不构成要约),并将尽力保证您所购商品与网站上公布的价格一致。
              <br><br>
              5.5购物网站已明示了商品产品的价格(含税、含包装,特殊情况除外)、可获性和相关信息。这类信息将随时更改而无须发送任何通知。
              <br><br>
              5.6配送:
              <br><br>
              5.6.1商品配送产生的配送费用将根据用户选择的送货方式不同而另行结算。5.6.2本网站根据用户指定的收货地址进行商品配送;网站上列出的送货时间系根据库存状况、正常处理流程和收货地址范围等信息估算,仅供用户参考,不作为对用户的到货时间承诺。
              <br><br>
              5.6.3本网站确认了用户订单后,由于供应商提价,税额变化,网站系统错误等意外情况而造成了价格变化,用户有权取消订单,并应及时通过电子邮件或电话通知网站的客户服务部。
              <br><br>
              5.6.4用户所订购的商品发生缺货,用户有权取消订单。
              <br><br>
              6、退换货及退款规则
              <br><br>
              6.1用户同意遵守本网站实行的“售后服务”约定。
              <br><br>
              6.2本网站保留对商品退换货的解释权和限制权。
              <br><br>
              6.3换货商品缺货或退货时产生的现金款项,退回方式根据支付方式包括但不限于以下形式:
              <br><br>
              ①网上支付的订单,退款退回至原支付卡。
              <br><br>
              ②银行转账或邮局汇款支付的订单,退款退回至下订单账户的“账户余额”中。
              <br><br>
              7、纠纷、争议及投诉处理
              <br><br>
              7.1用户在使用网站的过程中,发现可能有侵害自己权利的事实时,应及时通知网站并提供相应的证明材料。因投诉不实给网站或第三方造成损失的,应承担法律责任。
              <br><br>
              7.2网站有权接受并处理用户间因使用网站而产生的纠纷、争议及投诉,有权通过电子邮件等联系方式了解情况、并将所了解的情况通过电子邮件等方式通知对方。用户有义务在收到网站通知后,在指定的时间内提供相应的资料,配合纠纷、争议及投诉的情况了解与处理。对网站的处理结果不满意的,用户可以依法向有管辖权的法院起诉。
              <br><br>
              7.3经生效法律文书确认用户存在违法或违反本协议行为或者网站自行判断会员涉嫌存在违法或违反本协议行为的,网站有权公布用户的违法行为并做出相应处理,包括但不限于终止服务、永久禁止使用网站等。
              <br><br>
              8、中止与终止
              <br><br>
              对于用户的以下行为,网站有权在未告知的情况下中止或终止其相关服务或者拒绝其以任何形式使用网站,并保留追究您法律责任的权利:
              <br><br>
              8.1 用户同意,本网站有权随时对网站、政策和/或本协议作出变更;任何修改或补充在其被张贴在网站时立即生效,对双方都有约束力。重大变更将在网站首页页面提示。用户继续使用网站、购买商品、享受服务的,即视为对该等变更之确认。
              <br><br>
              8.2 如发生下列任何一种情形,网站有权无需通知而随时停止向用户提供服务:
              <br><br>
              ①用户提供的个人资料不真实;
              <br><br>
              ②用户违反本协议规定;
              <br><br>
              ③按照行政机关和/或授权职能部门的要求;
              <br><br>
              ④其他本网站认为需终止服务的情况。
              <br><br>
              8.3利用网站滥发重复信息,发布国家法律法规等禁止或限制发布的信息,发布不属于会员经营范围的信息,或与网站服务无关的信息;
              <br><br>
              8.3未经请求或授权向网站会员发送大量与业务不相关的信息;
              <br><br>
              8.4冒用其他企业的名义发布商业信息,进行商业活动;
              <br><br>
              8.5计划破坏、攻击网站网站电脑系统、网络的完整性,或者试图擅自进入网站网站电脑系统、网络;
              <br><br>
              8.6使用或提供含有毁坏、干扰、截取或侵占任何系统、数据或个人资料的任何电脑病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他破坏性程序;
              <br><br>
              8.7盗用他人在网站上的登录名和/或密码;未经网站同意的帐号出售或授权使用行为;通过或从事涉及网站的欺诈活动;通过或从事涉及网站的知识产权侵权行为;
              <br><br>
              8.8违反国家法律法规、规章制度、网站有关协议、规定、规则、程序和惯例的其他行为。
              <br><br>
              8.9本协议终止或更新时,用户未确认新的协议的。
              <br><br>
              8.10其它本公司认为需终止服务的情况。
              <br><br>
              9、责任声明
              <br><br>
              9.1鉴于网络服务的特殊性,网站不对除基本安全、稳定以外其的他外部干扰、黑客攻击等因素造成的用户与网站服务器之间的连接受阻而无法访问负责。用
              <br><br>
              户同意自己承担因网站意外中断、操作或传输的迟延、电脑病毒、网络连接故障、未经授权的进入等引起的损失。
              <br><br>
              9.2因不可抗力或者其他意外事件,使得本协议的履行不可能、不必要或者无意义的,双方均不承担责任。本合同所称之不可抗力意指不能预见、不能避免并不能克服的客观情况,包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况
              <br><br>
              9.3本网站无法保证网络信息服务的准确性,对用户因使用网站而造成的损失不负法律责任。用户可通过本网站有关服务获得第三方的某些内容或通往第三方网站的链接,但不表示本网站与这些第三方有任何关系。第三方的网站均由各经营者自行负责,不属于本网站控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、产品或其它资料,本网站对此不作保证、不承担任何责任、也不负有任何义务。用户对此自行加以判断,并承担因使用该等内容而引起的所有风险,包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险
              <br><br>
              9.4用户明确同意其使用本网站服务所存在的风险及其后果将完全由其自己承担,网站对用户不承担任何责任。如因用户违反有关法律、法规或本协议项下的任何条款而给网站或任何其他第三人造成损失,用户同意承担由此造成的损害赔偿责任。
              <br><br>
              10、知识产权
              <br><br>
              10.1本网站及本网站所有内容的选材、整理、编排、排版、汇编,以及由网站创作的文字、图片等作品的著作权,无论是否明示,均属网站所有。任何自然人、法人及其他组织未经网站书面授权或许可,不得修改、出版、传播、参与传播、销售、制作衍生作品,或以其他任何方式使用整体或部分的网站上的内容,包括但不限于公司信息、产品信息,网站将保留追究其法律责任的权利。
              <br><br>
              10.2未经网站授权,任何人不得使用网站任何商标、服务标记、标识、域名和其他显著品牌特征的权利。
              <br><br>
              10.3用户不得以他人资料/信息在本网站进行注册和购买商品。不应当将账号、密码转让或出借予他人使用。若发现用户账号遭他人非法使用,应立即通知网站。
              <br><br>
              10.4 用户同意接受本网站通过电子邮件、网页或其他合法方式向用户发送商品促销或其他相关商业信息。
              <br><br>
              10.5本网站及本网站所有内容均受《中华人民共和国著作权法》、《中华人民共和国商标法》等的法律保护。用户在本网站上发布的信息不得侵犯任何第三人的知识产权,未经相关权利人之事先书面同意,用户不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。
              <br><br>
              11、法律适用及管辖
              <br><br>
              11.1本协议的订立、变更、执行和解释及争议的解决,均应适用中华人民共和国法律。如与本协议有关的某一特定事项缺乏明确法律规定,则应参照通用的国际商业惯例和行业惯例。
              <br><br>
              11.2因本协议内容或其执行发生争议,双方应友好协商解决;协商不成时,任何一方均可向公司经营所在地的人民法院提起诉讼。
              <br><br>
              12、其他
              <br><br>
              12.1用户使用网站相关服务时,亦应知晓、认可并同意遵守相关服务的额外条款及条件。
              <br><br>
              12.2如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力,本协议的其余条款仍应有效并且有约束力。
              <br><br>
              12.3 本协议之条款是可分割的,任何协议条款被认定为无效或不可执行时,不影响其他条款之效力。
            </div>
          </div>
          <div v-show="index === '1-2'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              购物流程
            </div>
            <div class="contentDiv">
              1、搜索商品
              <br><br>
              本站为您提供了方便快捷的商品搜索功能：
              <br><br>
              （1）您可以通过在首页输入关键字的方法来搜索您想要购买的商品
              <br><br>
              （2）您还可以通过本站的分类导航栏来找到您想要购买的商品分类，根据分类找到您的商品
              <br><br>
              （3）观看搜索商品演示
              <br><br>
              2、放入购物车在您想要购买的商品的详情页点击“购买”，商品会添加到您的购物车中；您还可以继续挑选商品放入购物车，一起结算。
              <br><br>
              （1）在购物车中，系统默认每件商品的订购数量为一件，如果您想购买多件商品，可修改购买数量
              <br><br>
              （2）在购物车中，您可以将商品移至收藏，或是选择删除
              <br><br>
              （3）在购物车中，您可以直接查看到商品的优惠折和参加促销活动的商品名称、促销主题
              <br><br>
              （4）购物车页面下方的商品是本站根据您挑选的商品为您作出的推荐，若有您喜爱的商品，点击“放入购物车”即可
              <br><br>
              温馨提示：
              <br><br>
              （1）商品价格会不定期调整，最终价格以您提交订单后订单中的价格为准
              <br><br>
              （2）优惠政策、配送时间、运费收取标准等都有可能进行调整，最终成交信息以您提交订单时网站公布的最新信息为准
              <br><br>
              3、选择订单
              <br><br>
              （1）本站和商家的商品需要分别提交订单订购
              <br><br>
              （2）不同商家的商品需要分别提交订单订购
              <br><br>
              4、注册登陆
              <br><br>
              （1）老顾客：请在“登陆”页面输入Email地址或昵称、注册密码进行登陆
              <br><br>
              （2）新顾客：请在“新用户注册”页面按照提示完成注册
              <br><br>
              5、填写收货人信息
              <br><br>
              （1）请填写正确完整的收货人姓名、收货人联系方式、详细的收货地址和邮编，否则将会影响您订单的处理或配送
              <br><br>
              （2）您可以进入“我的本站—帐户管理—收货地址簿”编辑常用收货地址，保存成功后，再订购时，可以直接选择使用
              <br><br>
              6、选择收货方式
              <br><br>
              本站提供多种收货方式：
              <br><br>
              （1）普通快递送货上门
              <br><br>
              （2）加急快递送货上门
              <br><br>
              （3）普通邮递
              <br><br>
              （4）邮政特快专递(EMS)
              <br><br>
              详情请点击查看配送范围、时间及运费
              <br><br>
              7、选择支付方式
              <br><br>
              本站提供多种支付方式，订购过程中您可以选择：
              <br><br>
              （1）货到付款
              <br><br>
              （2）网上支付
              <br><br>
              （3）银行转帐
              <br><br>
              （4）邮局汇款
              <br><br>
              点击查看各种支付方式订单的支付期限
              <br><br>
              8、索取发票
              <br><br>
              请点击“索取发票”，填写正确的发票抬头、选择正确的发票内容，发票选择成功后，将于订单货物一起送达
              <br><br>
              点击查看发票制度
              <br><br>
              9、提交订单
              <br><br>
              （1）以上信息核实无误后，请点击“提交订单”，系统生成一个订单号，就说明您已经成功提交订单
              <br><br>
              （2）订单提交成功后，您可以登陆“我的本站”查看订单信息或为订单进行网上支付
              <br><br>
              特别提示
              <br><br>
              1、若您帐户中有礼品卡，可以在“支付方式”处选择使用礼品卡支付，详情请点击查看本站礼品卡
              <br><br>
              2、若您帐户中有符合支付该订单的礼券，在结算页面会有“使用礼券”按钮，您点击选择礼券即可，点击查看礼券使用规则
              <br><br>
              当您选择了礼券并点击“确定使用”后，便无法再取消使用该礼券
              <br><br>
              3、在订单提交高峰时段，新订单可能一段时间之后才会在“我的本站”中显示。如果您在“我的本站”中暂未找到这张订单，请您耐心等待
            </div>
          </div>
          <div v-show="index === '1-3'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              会员制度
            </div>
            <div class="contentDiv">
              会员级别共分七级，具体为：注册会员、铁牌会员、铜牌会员、银牌会员、金牌会员、钻石会员、双钻石会员，级别升降均由系统自动实现，无需申请。
              <br><br>
              注册会员：<br>
              申请条件：任何愿意到本站购物的用户都可以免费注册。<br>
              待  遇：可以享受注册会员所能购买的产品及服务。<br>
              铁牌会员：<br>
              申请条件：一年内有过成功消费的会员，金额不限。<br>
              待  遇：可以享受铁牌会员级别所能购买的产品及服务。<br>
              铜牌会员：<br>
              申请条件：一年内消费金额超过2000元（含）的会员。<br>
              待  遇：可以享受铜牌会员级别所能购买的产品及服务。<br>
              其它要求：<br>
              身份有效期为一年，一年有效期满后，如在该年度内累计消费金额不满1000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铁牌会员。<br>
              银牌会员：<br>
              申请条件：一年内消费金额超过5000元（含），需填写本人真实的身份证号码进行升级<br>
              待  遇：可以享受银牌会员级别所能购买的产品及服务。<br>
              其它要求：<br>
              身份有效期为一年，一年有效期满后，如在该年度内累计消费金额在1000元（含）——2500元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铜牌会员；如消费金额不满1000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铁牌会员。<br>
              金牌会员：<br>
              申请条件： 一年内累计消费金额超过10000 元（含）。<br>
              待  遇：<br>
              享有优先购物权 —— 对国内少见的优秀产品或者其它比较紧俏的产品具有优先购买权。<br>
              享受运费优惠政策（详见这里）<br>
              享有一年两次的特别针对金牌会员抽奖的权利<br>
              不定期举办个别产品针对金牌会员的优惠活动。<br>
              享有支付66元DIY装机服务费的权利。<br>
              其它相关要求：<br>
              身份有效期为一年，一年有效期满后，如在该年度内累计消费金额在2500元（含）——5000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为银牌会员；如消费金额在1000元（含）——2500元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铜牌会员；如消费金额不满1000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铁牌会员。 <br>
              钻石会员：<br>
              申请条件：一年内累计消费金额达到 30000 元（含）<br>
              享受金牌会员全部待遇。<br>
              享受运费优惠政策（详见这里）<br>
              享有支付30元DIY装机服务费的权利。<br>
              享受一定范围内免返修品快递运费的服务。（详情请查看售后返修品运费规定）<br>
              其它要求：<br>
              身份有效期为一年，一年有效期满后，如在该年度内累计消费金额在5000元（含）——15000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为金牌会员；如消费金额在2500元（含）——5000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为银牌会员；如消费金额在1000元（含）——2500元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铜牌会员；如消费金额不满1000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铁牌会员。<br>
              双钻石会员：<br>
              申请条件：个人用户，年消费金额在10万元（含）以上。<br>
              待  遇：<br>
              钻石会员的全部待遇都可以享受。<br>
              享有本站网站高管定期提供的沟通服务。<br>
              享有不需审核，只需报名，即可参加本站网站举办的网友见面会等网友活动。<br>
              享有客服专员定期回访征询意见服务。<br>
              其它要求：<br>
              身份有效期为一年，一年有效期满后，如在该年度内累计消费金额在15000元（含）——50000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为钻石会员；如消费金额在5000元（含）——15000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为金牌会员；如消费金额在2500元（含）——5000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为银牌会员；如消费金额在1000元（含）——2500元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铜牌会员；如消费金额不满1000元或一年内未完成10个（含）以上不同日期的订单，则系统自动将身份降为铁牌会员。
              <br><br>
              注：针对各个级别会员特别声明：<br>
              会员账号禁止转借或转让他人使用，如因转借或转让他人使用所带来的一切后果，本站网站概不负责，如被本站网站发现有转借或转让使用情况，本站网站则有权立即取消此会员账号的相应级别资格。<br>
              如本站网站发现相应的级别中有经销商，则本站网站有权立即取消此会员帐号的相应级别资格。
            </div>
          </div>
          <div v-show="index === '1-4'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              积分说明
            </div>
            <div class="contentDiv">
              所有会员在本站购物均可获得积分，积分可以用来参与兑换活动。本站会不定期推出各类积分兑换活动，请随时关注关于积分的活动告知。详情请点击查看以下各项说明。
              <br><br>
              积分获得
              <br><br>
              1、每一张成功交易的订单，所付现金部分（含本站礼品卡）都可获得积分，不同商品积分标准不同，获得积分以订单提交时所注明的积分为准。
              <br><br>
              2、贵宾会员购物时，将额外获得相应级别的级别赠分。
              <br><br>
              3、阶段性的积分促销活动，也会给您带来额外的促销赠分，详见积分活动。
              <br><br>
              4、促销商品不能获得积分。
              <br><br>
              …………………………………………………………………………………………
              <br><br>
              积分有效期
              <br><br>
              积分有效期：获得之日起到次年年底。
              <br><br>
              …………………………………………………………………………………………
              <br><br>
              查询积分
              <br><br>
              积分有效期：获得之日起到次年年底。
              <br><br>
              您可以在\"我的本站-我的积分\"中，查看您的累计积分。
              <br><br>
              …………………………………………………………………………………………
              <br><br>
              积分活动
              <br><br>
              本站会不定期地推出各种积分活动，请随时关注关于积分促销的告知。
              <br><br>
              1、会员可以用积分参与本站指定的各种活动，参与后会扣减相应的积分。
              <br><br>
              2、积分不可用于兑换现金，仅限参加本站指定兑换物品、参与抽奖等各种活动。
              <br><br>
              …………………………………………………………………………………………
              <br><br>
              会员积分计划细则
              <br><br>
              不同帐户积分不可合并使用；
              <br><br>
              ·本计划只适用于个人用途而进行的购物，不适用于团体购物、以营利或销售为目的的购买行为、其它非个人用途购买行为。
              <br><br>
              ·会员积分计划及原VIP制度的最终解释权归本站所有。
              <br><br>
              …………………………………………………………………………………………
              <br><br>
              免责条款
              <br><br>
              感谢您访问本站的会员积分计划，本计划由本站网站/或其关联企业提供。以上计划条款和条件，连同计划有关的任何促销内容的相应条款和条件，构成本计划会员与本站之间关于制度的完整协议。如果您使用本站，您就参加了本计划并接受了这些条款、条件、限制和要求。请注意，您对本站站的使用以及您的会员资格还受制于本站站上时常更新的所有条款、条件、限制和要求，请仔细阅读这些条款和条件。
              <br><br>
              协议的变更
              <br><br>
              本站可以在没有特殊通知的情况下自行变更本条款、本站的任何其它条款和条件、或您的计划会员资格的任何方面。对这些条款的任何修改将被包含在本站的更新的条款中。如果任何变更被认定为无效、废止或因任何原因不可执行，则该变更是可分割的，且不影响其它变更或条件的有效性或可执行性。在我们变更这些条款后，您对本站的继续使用，构成您对变更的接受。
              <br><br>
              终止
              <br><br>
              本站可以不经通知而自行决定终止全部或部分计划，或终止您的计划会员资格。即使本站没有要求或强制您严格遵守这些条款，也并不构成对属于本站的任何权利的放弃。如果您在本站的客户帐户被关闭，那么您也将丧失您的会员资格。对于该会员资格的丧失，您对本站不能主张任何权利或为此索赔。
              <br><br>
              责任限制
              <br><br>
              除了本站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因会员积分计划而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害，本站、本站的董事、管理人员、雇员、代理或其它代表在任何情况下都不承担责任。本站的全部责任，不论是合同、保证、侵权（包括过失）项下的还是其它的责任，均不超过您所购买的与该索赔有关的商品价值额。这些责任排除和限制条款将在法律所允许的最大限度内适用，并在您的计划会员资格被撤销或终止后仍继续有效。
            </div>
          </div>
          <div v-show="index === '1-5'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              交易条款
            </div>
            <div class="contentDiv">
              本站网站交易条款
              <br><br>
              本站网站和您之间的契约
              <br><br>
              1.本站网站将尽最大努力保证您所购商品与网站上公布的价格一致，但价目表和声明并不构成要约。本站网站有权在发现了其网站上显现的产品及订单的明显错误或缺货的情况下，单方面撤回。(参见下面相关条款)。
              <br><br>
              本站网站保留对产品订购的数量的限制权。 在下订单的同时，您也同时承认了您拥有购买这些产品的权利能力和行为能力，并且您对您在订单中提供的所有信息的真实性负责。
              <br><br>
              2. 价格变化和缺货
              <br><br>
              产品的价格和可获性都在本站网站上指明。这类信息将随时更改且不发任何通知。商品的价格都包含了增值税。送货费将另外结算，费用根据您选择的送货方式的不同而异。如果发生了意外情况，在确认了您的订单后，由于供应商提价，税额变化引起的价格变化，或是由于网站的错误等造成商品价格变化，您有权取消您的订单，并希望您能及时通过电子邮件或电话通知本站客户服务部。
              <br><br>
              您所订购的商品，如果发生缺货，您有权取消订单。
              <br><br>
              3. 邮件/短信服务
              <br><br>
              本站网站保留通过邮件和短信的形式，对本网站注册、购物用户发送订单信息、促销活动等告知服务的权利。如果您在本站网站注册、购物，表明您已默示同意接受此项服务。
              <br><br>
              若您不希望接收本站网站的邮件，请在邮件下方输入您的E-mail地址自助完成退阅；
              <br><br>
              若您不希望接收本站网站的短信，请提供您的手机号码 联系客服 处理。
              <br><br>
              4. 送货
              <br><br>
              本站网站将会把产品送到您所指定的送货地址。所有在本站网站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。参考时间不代表等同于到货时间。
              <br><br>
              5.退款政策
              <br><br>
              退货或换货商品缺货时产生的现金款项，退回方式视支付方式的不同而不同：
              <br><br>
              1） 网上支付的订单，退款退回至原支付卡；
              <br><br>
              2） 银行转帐或邮局汇款支付的订单，退款退回至下订单账户的账户余额中。
              <br><br>
              6. 条款的修正
              <br><br>
              这些交易条件的条款适用于本站网站为您提供的产品销售服务。这些条款将有可能不时的被修正。任何修正条款的发生，本站网站都将会及时公布。
              <br><br>
              7. 条款的可执行性
              <br><br>
              如果出于任何原因，这些条款及其条件的部分不能得以执行，其他条款及其条件的有效性将不受影响。
              <br><br>
              8. 适用的法律和管辖权
              <br><br>
              您和本站网站之间的契约将适用中华人民共和国的法律，所有的争端将诉诸于本站网站所在地的人民法院。
              <br><br>
              9、本站网站会员制计划（VIP计划）协议的变更/终止/责任限制
              <br><br>
              本站网站的会员制计划（VIP计划），本计划由本站网站/或其关联企业提供。以上计划条款和条件，连同计划有关的任何促销内容的相应条款和条件，构成本计划会员与本站之间关于制度的完整协议。如果您参加计划，您就接受了这些条款、条件、限制和要求。请注意，您对本站网站的使用以及您的会员资格还受制于本站网站上时常更新的所有条款、条件、限制和要求，请仔细阅读这些条款和条件。
              <br><br>
              协议的变更
              <br><br>
              本站网站可以在没有特殊通知的情况下自行变更本条款、本站网站的任何其它条款和条件、或您的计划会员资格的任何方面。 对这些条款的任何修改将被包含在本站网站的更新的条款中。如果任何变更被认定为无效、废止或因任何原因不可执行，则该变更是可分割的，且不影响其它变更或条件的有效性或可执行性。在我们变更这些条款后，您对本站网站的继续使用，构成您对变更的接受。如果您不同意本使用交易条款中的任何一条，您可以不使用本站网站。
              <br><br>
              10、本站网站的帐户余额自助提现功能
              <br><br>
              本站网站为您提供了帐户余额自助提现功能，在提交提现申请单时，您也同时承认了您拥有提现账户余额的权利能力和行为能力，并且将对您在申请单中提供的所有信息的真实性负责。
              <br><br>
              用户在申请使用本站网站网络服务时，必须准确提供必要的资料，如资料有任何变动 ，请在本站网站产品网站上及时更新。
              <br><br>
              用户注册成功后，本站网站将为其开通一个账户，为用户在本站网站交易及使用本站网站服务时的唯一身份标识，该账户的登录名和密码由用户负责保管；用户应当对以其账户进行的所有活动和事件负法律责任。
              <br><br>
              终止
              <br><br>
              本站网站可以不经通知而自行决定终止全部或部分计划，或终止您的计划会员资格。即使本站网站没有要求或强制您严格遵守这些条款，也并不构成对属于本站网站的任何权利的放弃。
              <br><br>
              如果您在本站网站的客户账户被关闭，那么您也将丧失您的会员资格。对于该会员资格的丧失，您对本站网站不能主张任何权利或为此索赔。
              <br><br>
              责任限制
              <br><br>
              除了本站网站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因VIP计划而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害， 本站网站、本站网站的董事、管理人员、雇员、代理或其它代表在任何情况下都不承担责任。本站网站的全部责任 ，不论是合同、保证、侵权（包括过失）项下的还是其它的责任，均不超过您所购买的与该索赔有关的商品价值额。这些责任排除和限制条款将在法律所允许的最大限度内适用，并在您的计划会员资格被撤销或终止后仍继续有效。
              <br><br>
              隐私声明
              <br><br>
              电子通讯
              <br><br>
              当您访问本站网站或给我们发送电子邮件时，您与我们用电子方式进行联系。您同意以电子方式接受我们的信息。我们将用电子邮件或通过在本站网站上发布通知的方式与您进行联系。您同意我们用电子方式提供给您的所有协议、通知、披露和其他信息是符合此类通讯必须是书面形式的法定要求的。如果本站网站能够证明以电子形式的信息已经发送给您或者本站网站立即在本站网站上张贴这样的通知，将被视为您已收到所有协议、声明、披露和其他信息
              <br><br>
              版权声明
              <br><br>
              本站网站上的所有内容诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件、商标都是本站网站或其关联公司或其内容提供者的财产，受中国和国际版权法的保护。未经本站网站书面授权或许可 ，不得以任何目的对本站网站或其任何部分进行复制、复印、仿造、出售、转售、访问、或以其他方式加以利用。
              <br><br>
              您的账户
              <br><br>
              如果您使用本站网站，您有责任对您的账户和密码保守秘密并对进入您的计算机作出限制，并且您同意对在您的账户和密码下发生的所有活动承担责任。本站网站的确销售供儿童使用的产品，但只将它们销售给成年人。如果您在18岁以下，您只能在父母或监护人的参与下才能使用本站网站。本站网站及其关联公司保留在中华人民共和国法律允许的范围内独自决定拒绝服务、关闭账户、清除或编辑内容或取消订单的权利。
              <br><br>
              评论、意见、消息和其他内容
              <br><br>
              访问者可以张贴评论、意见及其他内容，以及提出建议、主意、意见、问题或其他信息，只要内容不是非法、淫秽、威胁、诽谤、侵犯隐私、侵犯知识产权或以其他形式对第三者构成伤害或侵犯或令公众讨厌，也不包含软件病毒、政治宣传、商业招揽、连锁信、大宗邮件或任何形式的\"垃圾邮件\"。您不可以使用虚假的电子邮件地址、冒充任何他人或实体或以其它方式对卡片或其他内容的来源进行误导。本站网站保留清除或编辑这些内容的权利（但非义务），但不对所张贴的内容进行经常性的审查。
              <br><br>
              如果您确实张贴了内容或提交了材料，除非我们有相反指示，您授予本站网站及其关联公司非排他的、免费的、永久的、不可撤销的和完全的再许可权而在全世界范围内任何媒体上使用、复制、修改、改写、出版、翻译、创作衍生作品、分发和展示这样的内容。您授予本站网站及其关联公司和被转许可人使用您所提交的与这些内容有关的名字的权利，如果他们选择这样做的话。您声明并担保您拥有或以其它方式控制您所张贴内容的权利，内容是准确的，对您所提供内容的使用不违反本政策并不会对任何人和实体造成伤害。您声明并保证对于因您所提供的内容引起的对本站网站或其关联公司的损害进行赔偿。本站网站有权（但非义务）监控和编辑或清除任何活动或内容。本站网站对您或任何第三方所张贴的内容不承担责任。
              <br><br>
              合同缔结
              <br><br>
              如果您通过我们网站订购产品，您的订单就成为一种购买产品的申请或要约。我们将发送给您一封确认收到订单的电子邮件，其中载明订单的细节。但是只有当我们向您发出送货确认的电子邮件通知您我们已将产品发出时，我们对您合同申请的批准与接受才成立。如果您在一份订单里订购了多种产品并且我们只给您发出了关于其中一部分的发货确认电子邮件，那么直到我们发出关于其他产品的发货确认电子邮件，关于那部分产品的合同才成立。当您所购买的商品离开了本站网站或其关联公司的库房时，该物品的所有权和灭失风险即转移到您这一方。
              <br><br>
              产品说明
              <br><br>
              本站网站及其关联公司努力使产品说明尽可能准确。不过，我们并不保证产品说明或本站网站上的其他内容是准确的、完整的、可靠的、最新的或无错误的。如果本站网站提供的产品本身并非如说明所说，您唯一的救济是将该未经使用过的产品退还我们。
              <br><br>
              价格
              <br><br>
              直到您发出订单，我们才能确认商品的价格。尽管我们做出最大的努力，我们的商品目录里的一小部分商品可能会有定价错误。如果我们发现错误定价，我们将采取下列之一措施：
              <br><br>
              （i） 如果某一商品的正确定价低于我们的错误定价，我们将按照较低的定价向您销售交付该商品。
              <br><br>
              （ii） 如果某一商品的正确定价高于我们的错误定价，我们会根据我们的情况决定,是否在交付前联系您寻求您的指示, 或者取消订单并通知您。
              <br><br>
              其他企业
              <br><br>
              本站网站及其关联企业之外的其他人可能在本站网站上经营商店、提供服务或者销售产品。另外，我们提供与关联公司和其他企业的链接。我们不负责审查和评估也不担保任何这些企业或个人的待售商品及它们网站的内容。我们对所有这些企业或任何其他第三人或其网站的行为、产品和内容不承担责任。您应仔细阅读它们自己的隐私政策及使用条件。
            </div>
          </div>
          <div v-show="index === '1-6'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              订单状态
            </div>
            <div class="contentDiv">
              一个本站网站的新订单从下单到订单完成，会经历各种状态，我们会将各种状态显示在订单详情页面，希望以此种方式让您更好的了解订单情况，及时跟踪订单状态，打消疑虑并顺利完成购物。以下是订单状态的简单说明：
              <br><br>
              等待付款：如果您选择“在线支付”“银行卡转账”“邮局汇款”“公司转账”“分期付款”“高校-自己支付”“高校-代理垫付”这几种支付方式，在成功支付且得到财务确认之前，订单状态会显示为等待付款；
              <br><br>
              正在配货：该状态说明本站网站正在为您的订单进行配货，包括5个子状态
              <br><br>
              1）打印：将您订购的商品打印成单，便于出库员取货
              <br><br>
              2）出库：出库员找到您订购的商品并出库
              <br><br>
              3）扫描：扫描员扫描您订购的商品并确认商品成功出库
              <br><br>
              4）打包：打包员将您订购的商品放入包裹以便运输
              <br><br>
              5）发货：发货员将您的包裹发货运输
              <br><br>
              本站网站送货：您订购的商品已经发货，正在运送途中
              <br><br>
              收货并确认：货物已发出一段时间，如果您已收到货物可以点击确认按钮进行确认
              <br><br>
              上门自提：该状态说明您订购的商品已经送至相应自提点，请您尽快到自提点提货
              <br><br>
              已完成：此次交易已经完成，希望能得到您的满意
              <br><br>
              已锁定：如果您修改了订单但没有修改成功，则系统会自动锁定您的订单，您可以在订单列表页面点击操作栏中的“解锁订单”使订单恢复正常
              <br><br>
              订单待审核：该状态说明您订购的某类商品缺货，需要本站网站将货物备齐后订单才会恢复正常状态，此状态下请您不要进行付款操作，以免货物无法备齐而占用您的货款
              <br><br>
              修改订单常见问题：
              <br><br>
              1、什么时候允许修改订单？
              <br><br>
              您在本站网站下单后，本站网站后台程序会通过一系列算法来判断您的订单是否可以修改，如果可以修改，您在订单操作一列可以看到“修改订单”链接，此时说明订单可以修改。如果没有此链接，说明该订单不可修改。
              <br><br>
              一般来说，在您选购的商品没有打印完毕之前，都是可以修改订单的。
              <br><br>
              2、我能修改订单的哪些内容？
              <br><br>
              1)修改购物车内的商品数量，增加或删除商品；（暂不支持添加套装）
              <br><br>
              2)修改收货人信息、配送方式、发票信息、订单备注；
              <br><br>
              3)添加优惠券或删除已使用的优惠券；
              <br><br>
              注：由于目前暂不支持修改支付方式，所以一些与支付方式相关联的收货地址可能也无法修改。
              <br><br>
              3、修改订单时，订单为什么会被锁定？
              <br><br>
              为了避免您在修改订单的同时，您的订单继续被程序处理和执行，我们会在您修改订单过程中锁定您的订单，直到您完成修改并点击了“提交订单”按钮。
              <br><br>
              如果您在修改过程当中放弃了修改，建议您返回订单列表页面点击操作栏中的“解锁订单”，否则您的订单将在2个小时后解锁，将影响您订单的生产时间和收货时间。
              <br><br>
              4、如果购物车里某一款商品下单时的价格和修改订单当时的价格不一致，按哪个来算商品价格呢？
              <br><br>
              如果您不修改该商品的购买数量，那么价格和赠品都会维持您下单时的状态不变；
              <br><br>
              如果您修改了该商品购买数量或者添加了新商品，那么价格和赠品都会与本站网站最新显示的价格和赠品一致。
              <br><br>
              如果您添加了新商品，那么新商品的价格与本站网站最新显示的价格和赠品一致。
              <br><br>
              5、可以先申请价保后再修改订单吗？
              <br><br>
              不可以，如果你对某商品申请了价保，那么该商品将不能进行修改和删除，除非您删除整个订单。
            </div>
          </div>
          <div v-show="index === '2-1'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              货到付款
            </div>
            <div class="contentDiv">
              货到付款：货物送到订单指定的收货地址后，由收货人支付货款给送货人员
              <br><br>
              货到付款适用于加急快递、普通快递送货上门的订单。请您在订购过程的“付款方式”处，选择货到付款
              <br><br>
              温馨提示：
              <br><br>
              1、货到付款仅限支付现金
              <br><br>
              2、签收时，请您仔细核兑款项、务必作到货款两清，若事后发现款项错误，我们将无法再核实确认点击查看当当网签收验货政策
              <br><br>
              3、部分商店街的商家不支持货到付款，请您通过网上支付、邮局汇款、银行转帐方式支付
            </div>
          </div>
          <div v-show="index === '2-2'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              在线支付
            </div>
            <div class="contentDiv">
              1、本站网站提供的在线支付方式
              <br><br>
              本站网站目前有以下支付平台可供选择：
              <br><br>
              （1）工商银行网上银行支付平台，支持工商银行银行卡网上在线支付
              <br><br>
              （2）招商银行网上银行支付平台，支持招商银行银行卡网上在线支付
              <br><br>
              （3）建设银行网上银行支付平台，支持建设银行银行卡网上在线支付
              <br><br>
              （4）农业银行网上银行支付平台，支持 农业银行银行卡网上在线支付
              <br><br>
              （5）支付宝支付平台，关于支付宝的支付帮助请查看
              <br><br>
              （6）财付通支付平台，关于财付通的支付帮助请查看
              <br><br>
              （7）快钱99Bill支付平台，关于快钱支付平台的支付帮助请查看
              <br><br>
              （8）环迅IPS，关于环迅支付平台的支付帮助请查看
              <br><br>
              （9）本站网站虚拟账户支付
              <br><br>
              2、如您是第一次进行网上在线支付，建议事先拨打银行卡所属发卡银行的热线电话，详细咨询可在其网上进行在线支付的银行卡种类及相关开通手续。
            </div>
          </div>
          <div v-show="index === '2-3'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              余额支付
            </div>
            <div class="contentDiv">
              用户还可以通过使用账户中心中的余额来对订单进行支付
            </div>
          </div>
          <div v-show="index === '3-1'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              加急快递
            </div>
            <div class="contentDiv">
              如何正确选择加急配送服务
              <br><br>
              北京、天津、上海、广州、深圳、廊坊6个城市地区的用户，并且为当地发货订单，用户可在结算中心“送货方式”部分选择加急快递送货上门服务。
              <br><br>
              常见问题解答：
              <br><br>
              1.我的订单什么时候可以送到？
              <br><br>
              具体配送时间根据不同城市略有不同，请查看配送范围及运费
              <br><br>
              2.\"加急快递送货上门\"的费收取标准？
              <br><br>
              北京、天津、上海、广州、深圳、廊坊6个城市的“加急快递送货上门”配送费为10元/单。
            </div>
          </div>
          <div v-show="index === '3-2'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              商品验货与签收
            </div>
            <div class="contentDiv">
              快递送货上门、圆通快递的订单
              <br><br>
              1、签收时仔细核对：商品及配件、商品数量、本站网站的发货清单、发票（如有）、三包凭证（如有）等
              <br><br>
              2、若存在包装破损、商品错误、商品少发、商品有表面质量问题等影响签收的因素，请您一定要当面向送货员说明情况并当场整单退货
              <br><br>
              邮局邮寄的订单
              <br><br>
              1、请您一定要小心开包，以免尖锐物件损伤到包裹内的商品
              <br><br>
              2、签收时仔细核对：商品及配件、商品数量、本站网站的发货清单、发票（如有）、三包凭证（如有）等
              <br><br>
              3、若包装破损、商品错误、商品少发、商品存在表面质量问题等，您可以选择整单退货；或是求邮局开具相关证明后签收，然后登陆本站网站申请退货或申请换货
              <br><br>
              温馨提示
              <br><br>
              1、货到付款的订单送达时，请您当面与送货员核兑商品与款项，确保货款两清；若事后发现款项有误，本站网站将无法为您处理
              <br><br>
              2、请收货时务必认真核对，若您或您的委托人已签收，则说明订单商品正确无误且不存在影响使用的因素，本站网站有权不受理因包装或商品破损、商品错漏发、商品表面质量问题、商品附带品及赠品少发为由的退换货申请
              <br><br>
              3、部分商品由商店街的商家提供,这部分商品的验货验收不在本站网站承诺的范围内
            </div>
          </div>
          <div v-show="index === '4-1'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              换货说明
            </div>
            <div class="contentDiv">
              本站网站承诺自顾客收到商品之日起15日内（以发票日期为准，如无发票以本站网站发货清单的日期为准），如符合以下条件，我们提供换货服务：
              <br><br>
              1、商品及商品本身的外包装没有损坏，保持本站网站出售时的原质原样；
              <br><br>
              2、注明换货原因，如果商品存在质量问题，请务必说明；
              <br><br>
              3、确保商品及配件、附带品或者赠品、保修卡、三包凭证、发票、本站网站发货清单齐全；
              <br><br>
              4、如果成套商品中有部分商品存在质量问题，在办理换货时，必须提供成套商品；
              <br><br>
              5、本站网站中的部分商品是由与本站网站签订合同的商家提供的，这些商品的换货请与商家联系
              <br><br>
              以下情况不予办理换货：
              <br><br>
              1、任何非由本站网站出售的商品；
              <br><br>
              2、任何已使用过的商品，但有质量问题除外；
              <br><br>
              3、任何因非正常使用及保管导致出现质量问题的商品。
              <br><br>
              4、所有未经客服确认擅自退回的商品，换货申请无法受理。
              <br><br>
              特殊说明：
              <br><br>
              1、食品、保健食品类：
              <br><br>
              食品类商品不予换货，但有质量问题除外；如商品过期或距离保质期结束不到3个月。
              <br><br>
              2、美妆个护类：
              <br><br>
              化妆品及个人护理用品属于特殊商品不予换货，但有质量问题除外，如商品包装破损，商品过期或离过期不到3个月。我们保证商品的进货渠道和质量，如果您在使用时对商品质量表示置疑，请出具书面鉴定，我们会按照国家法律规定予以处理。因个人喜好（气味，色泽、型号，外观）和个人肤质不同要求的换货将无法受理。
              <br><br>
              3、母婴用品：
              <br><br>
              1)商品签收后不予换货，但有质量问题除外，洗涤方法参考说明，正常缩水10%以内正常，不属于质量问题。
              <br><br>
              2)因个人原因造成的商品损坏（如自行修改尺寸，洗涤，长时间穿着等），不予换货。
              <br><br>
              3)商品吊牌，包装破损，发货单、商品附件（如纽扣等）、说明书、保修单、标签等丢失，不予换货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              4、服装类商品：
              <br><br>
              1)内衣类商品，如内衣裤，袜子，文胸类商品，除质量问题除外，不予换货。
              <br><br>
              2)因个人原因造成的商品损坏（如自行修改尺寸，洗涤，长时间穿着等），不予换货。
              <br><br>
              3)商品吊牌，包装破损，发货单、商品附件（如纽扣等）、说明书、保修单、标签等丢失，不予换货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              5、鞋帽箱包类：
              <br><br>
              商品吊牌，包装破损，发货单、商品配件（如配饰挂坠等）、说明书、保修单、标签等丢失，不予换货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              6、玩具类：
              <br><br>
              商品签收后不予换货，但有质量问题除外。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              7、家居类商品：
              <br><br>
              1)因个人原因造成的商品损坏（如自行修改尺寸，洗涤），不予换货。
              <br><br>
              2)商品吊牌，包装破损，发货单、商品配件、说明书、保修单、标签等丢失，不予换货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              8、手表类商品：
              <br><br>
              手表类商品换货说明请您以商品的单品页面说明为准；
              <br><br>
              以下情况不予办理换货：
              <br><br>
              1)如果商品自身携带的产品序列号与本站网站售出的不符；
              <br><br>
              2)缺少随商品附带的保修卡、发票、配件等；
              <br><br>
              3)商品已打开塑封包装或撕开开箱即损贴纸者，但有质量问题除外；
              <br><br>
              4)将商品存储、暴露在超出商品适宜的环境中；
              <br><br>
              5)未经授权的修理、误用、疏忽、滥用、事故、改动、不正确的安装；
              <br><br>
              6)食物或液体溅落造成损坏；
              <br><br>
              7)商品使用中出现的磨损，非质量问题。
              <br><br>
              8)手表表带经过调整，但有质量问题除外。
              <br><br>
              9)非质量问题。
              <br><br>
              9、珠宝首饰类及礼品类商品：
              <br><br>
              1)对于附带国家级宝玉石鉴定中心出具的鉴定证书的，非质量问题不予换货。
              <br><br>
              顾客在收到商品之日起（以发票日期为准）3个月内，如果出现质量问题，请到当地的质量监督部门-珠宝玉石质量检验中心进行检测，如检测报告确认属于质量问题，请与本站网站客服中心联系办理换货事宜。
              <br><br>
              换货时，请您务必将商品的外包装、内带附件、鉴定证书、说明书等随同商品一起退回。
              <br><br>
              2)对于高档首饰都附带国家级宝玉石鉴定中心出具的鉴定证书，如果您对此有任何质疑，请到出具该证书的鉴定机构进行复检。
              <br><br>
              3)瑞士军刀、zippo打火机、钻石、18K金，如无质量问题不换货，有质量问题请出示检测报告，方可换货。
              <br><br>
              为了保证您的利益，请您在收到商品时，仔细检查，如果您发现有任何问题，请您当时指出，并办理换货。
              <br><br>
              10、软件类商品：
              <br><br>
              软件类商品换货说明请您以商品的单品页面说明为准；
              <br><br>
              如出现质量问题请您直接按照说明书上的联系方式与厂家的售后部门联系解决；已打开塑封包装，不予退换货，但有质量问题除外。
              <br><br>
              11、手机、数码相机、数码摄像机、笔记本电脑等商品：
              <br><br>
              1)顾客收到商品之日起（以发票日期为准）七日内，有非人为质量问题凭有效检测报告可选择退换货。
              <br><br>
              2)顾客收到商品之日起（以发票日期为准）八至十五日内，有非人为质量问题凭有效检测报告可选择更换同型号商品。
              <br><br>
              3)顾客收到商品之日起（以发票日期为准）十六日至一年内，有非人为质量问题可在当地保修点免费保修。配件保修请参阅保修卡。
              <br><br>
              4)为了您的自身权益请妥善保存发票和保修卡，如有发生质量问题请携带发票和保修卡及时到当地检测点检测，以免给您造成不必要的损失。
              <br><br>
              5)退换货要求：保修卡、发票、本站网站发货清单、有效检测报告一律齐全，并且配件完整，包装盒完好，否则将不予受理。
              <br><br>
              6)全国各地检测、保修点请在保修卡中查找。
              <br><br>
              7)不接受无检测报告,并且不在规定时间内的退换货要求。
              <br><br>
              8)换机产生的邮费由买卖双方各自承担。换货商品一律以邮寄的方式发出。
              <br><br>
              9)换货地址及联系电话详见各商品页面“售后服务”。
              <br><br>
              10)如需换货请您先联系本站网站客服，在客服人员指导下，一律以邮寄方式完成换货。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主
              <br><br>
              12、数码类（手机、数码相机、数码摄像机、笔记本电脑除外）
              <br><br>
              1)商品换货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理换货事宜。
              <br><br>
              换货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              13、电脑办公类
              <br><br>
              1)商品换货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理换货事宜。
              <br><br>
              换货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              14、家电类
              <br><br>
              商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系，如果确认属于质量问题，持厂家出具质量问题检测报告与本站网站客服中心联系办理换货事宜。
              <br><br>
              换货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              15、康体保健器材类商品
              <br><br>
              1)商品换货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理换货事宜。
              <br><br>
              换货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
            </div>
          </div>
          <div v-show="index === '4-2'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              退货说明
            </div>
            <div class="contentDiv">
              本站网站承诺自顾客收到商品之日起7日内（以发票日期为准，如无发票以本站网站发货清单的日期为准），如符合以下条件，我们将提供全款退货的服务：
              <br><br>
              1、商品及商品本身的外包装没有损坏，保持本站网站出售时的原质原样；
              <br><br>
              2、注明退货原因，如果商品存在质量问题，请务必说明；
              <br><br>
              3、确保商品及配件、附带品或者赠品、保修卡、三包凭证、发票、本站网站发货清单齐全；
              <br><br>
              4、如果成套商品中有部分商品存在质量问题，在办理退货时，必须提供成套商品；
              <br><br>
              5、本站网站中的部分商品是由与本站网站签订合同的商家提供的，这些商品的退货请与商家联系
              <br><br>
              以下情况不予办理退货：
              <br><br>
              1、任何非由本站网站出售的商品；
              <br><br>
              2、任何已使用过的商品，但有质量问题除外；
              <br><br>
              3、任何因非正常使用及保管导致出现质量问题的商品。
              <br><br>
              特殊说明：
              <br><br>
              1、食品、保健食品类：
              <br><br>
              食品类商品不予退货，但有质量问题除外；如商品过期或距离保质期结束不到3个月。
              <br><br>
              2、美妆个护类：
              <br><br>
              化妆品及个人护理用品属于特殊商品不予退货，但有质量问题除外，如商品包装破损，商品过期或离过期不到3个月。我们保证商品的进货渠道和质量，如果您在使用时对商品质量表示置疑，请出具书面鉴定，我们会按照国家法律规定予以处理。因个人喜好（气味，色泽、型号，外观）和个人肤质不同要求的退货将无法受理。
              <br><br>
              3、母婴用品：
              <br><br>
              1)商品签收后不予退货，但有质量问题除外，洗涤方法参考说明，正常缩水10%以内正常，不属于质量问题。
              <br><br>
              2)因个人原因造成的商品损坏（如自行修改尺寸，洗涤，长时间穿着等），不予退货。
              <br><br>
              3)商品吊牌，包装破损，发货单、商品附件（如纽扣等）、说明书、保修单、标签等丢失，不予退货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              4、服装类商品：
              <br><br>
              1)内衣类商品，如内衣裤，袜子，文胸类商品，除质量问题除外，不予退货。
              <br><br>
              2)因个人原因造成的商品损坏（如自行修改尺寸，洗涤，长时间穿着等），不予退货。
              <br><br>
              3)商品吊牌，包装破损，发货单、商品附件（如纽扣等）、说明书、保修单、标签等丢失，不予退货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              5、鞋帽箱包类：
              <br><br>
              商品吊牌，包装破损，发货单、商品配件（如配饰挂坠等）、说明书、保修单、标签等丢失，不予退货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              6、玩具类：
              <br><br>
              商品签收后不予退货，但有质量问题除外。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              7、家居类商品：
              <br><br>
              1)因个人原因造成的商品损坏（如自行修改尺寸，洗涤），不予退货。
              <br><br>
              2)商品吊牌，包装破损，发货单、商品配件、说明书、保修单、标签等丢失，不予退货。
              <br><br>
              注1：图片及信息仅供参考，不属质量问题。因拍摄灯光、显示器分辨率等原因可能会造成轻微色差，在网购中属于正常现象，一切以实物为准。
              <br><br>
              注2：品牌商品按其三包约定执行。
              <br><br>
              8、手表类商品：
              <br><br>
              手表类商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              以下情况不予办理退货：
              <br><br>
              1)如果商品自身携带的产品序列号与本站网站售出的不符；
              <br><br>
              2)缺少随商品附带的保修卡、发票、配件等；
              <br><br>
              3)商品已打开塑封包装或撕开开箱即损贴纸者，但有质量问题除外；
              <br><br>
              4)将商品存储、暴露在超出商品适宜的环境中；
              <br><br>
              5)未经授权的修理、误用、疏忽、滥用、事故、改动、不正确的安装；
              <br><br>
              6)食物或液体溅落造成损坏；
              <br><br>
              7)商品使用中出现的磨损，非质量问题。
              <br><br>
              8)手表表带经过调整，但有质量问题除外。
              <br><br>
              9)非质量问题。
              <br><br>
              9、珠宝首饰类及礼品类商品：
              <br><br>
              1)对于附带国家级宝玉石鉴定中心出具的鉴定证书的，非质量问题不予退货。
              <br><br>
              顾客在收到商品之日起（以发票日期为准）3个月内，如果出现质量问题，请到当地的质量监督部门-珠宝玉石质量检验中心进行检测，如检测报告确认属于质量问题，请与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、鉴定证书、说明书等随同商品一起退回。
              <br><br>
              2)对于高档首饰都附带国家级宝玉石鉴定中心出具的鉴定证书，如果您对此有任何质疑，请到出具该证书的鉴定机构进行复检。
              <br><br>
              3)瑞士军刀、zippo打火机、钻石、18K金，如无质量问题不退货，有质量问题请出示检测报告，方可退货。
              <br><br>
              为了保证您的利益，请您在收到商品时，仔细检查，如果您发现有任何问题，请您当时指出，并办理退货。
              <br><br>
              10、软件类商品：
              <br><br>
              软件类商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              如出现质量问题请您直接按照说明书上的联系方式与厂家的售后部门联系解决；已打开塑封包装，不予退换货，但有质量问题除外。
              <br><br>
              11、手机、数码相机、数码摄像机、笔记本电脑等商品：
              <br><br>
              1)顾客收到商品之日起（以发票日期为准）七日内，有非人为质量问题凭有效检测报告可选择退货。
              <br><br>
              2)顾客收到商品之日起（以发票日期为准）八至十五日内，有非人为质量问题凭有效检测报告可选择更换同型号商品。
              <br><br>
              3)顾客收到商品之日起（以发票日期为准）十六日至一年内，有非人为质量问题可在当地保修点免费保修。配件保修请参阅保修卡。
              <br><br>
              4)为了您的自身权益请妥善保存发票和保修卡，如有发生质量问题请携带发票和保修卡及时到当地检测点检测，以免给您造成不必要的损失。
              <br><br>
              5)退换货要求：保修卡、发票、本站网站发货清单、有效检测报告一律齐全，并且配件完整，包装盒完好，否则将不予受理。
              <br><br>
              6)全国各地检测、保修点请在保修卡中查找。
              <br><br>
              7)不接受无检测报告,并且不在规定时间内的退换货要求。
              <br><br>
              8)换机产生的邮费由买卖双方各自承担。换货商品一律以邮寄的方式发出。
              <br><br>
              9)换货地址及联系电话详见各商品页面“售后服务”。
              <br><br>
              10)如需退货请您先联系本站网站客服，在客服人员指导下，一律以邮寄方式完成退货。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主
              <br><br>
              12、数码类（手机、数码相机、数码摄像机、笔记本电脑除外）
              <br><br>
              1)商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              13、电脑办公类
              <br><br>
              1)商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              14、家电类
              <br><br>
              商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系，如果确认属于质量问题，持厂家出具质量问题检测报告与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              15、康体保健器材类商品
              <br><br>
              1)商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              2)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
              <br><br>
              16、汽车用品类
              <br><br>
              1)汽车养护用品、汽车耗材开封后不予退货。（例如车蜡、防护贴膜、清洗剂、车内空气净化、车用油品等）
              <br><br>
              2)商品退货说明请您以商品的单品页面说明为准；
              <br><br>
              3)商品如出现质量问题，请先行按照说明书上的联系方式与厂家的售后部门联系。如果确认属于质量问题，请与本站网站客服中心联系办理退货事宜。
              <br><br>
              退货时，请您务必将商品的外包装、内带附件、保修卡、说明书、发票等随同商品一起退回。
              <br><br>
              注：平邮客户以包裹单上的签收日期为主。
            </div>
          </div>
          <div v-show="index === '4-3'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              退/换货注意事项
            </div>
            <div class="contentDiv">
              1、邮寄时请认真填写以下信息，否则将影响您的退/换货办理：
              <br><br>
              ·您的姓名<br>
              ·收货地址、Email<br>
              ·订单号、商品名称和型号<br>
              ·退/换货原因
              <br><br>
              2、如需检验报告的商品,您还需要提供检验报告，查看退货说明、换货说明；
              <br><br>
              3、请您在收到商品后尽快进行“确认收货”操作，否则将会影响您的退/换货的办理；
              <br><br>
              4、本站网站的部分商品是由与本站网站合作的商家提供的，此商品的退/换货流程请直接与商家联系。
            </div>
          </div>
          <div v-show="index === '4-4'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              余额的使用与提现
            </div>
            <div class="contentDiv">
              一、账户余额支付：
              <br><br>
              1、“我的本站网站-账户管理”是您在本站网站上的专用帐户。
              <br><br>
              2、账户内的金额是顾客在本站网站购物后余下的现金或通过邮局、银行多余汇款的总和，如下图：
              <br><br>
              3、您可登录“我的本站网站”查询余额。
              <br><br>
              4、在订单结算时，系统将自动使用您的账户余额，您只需支付其余货款：
              <br><br>
              5、如果您的账户余额足以支付订单，您仍需选择一种支付方式，否则将无法提交订单。
              <br><br>
              二、账户余额提现
              <br><br>
              本站网站为您提供了账户余额提现功能，您可以将您在本站网站账户余额中的可用余额提取为现金，我们会已邮局汇款的方式退给您。
              <br><br>
              账户余额提现的流程：
              <br><br>
              温馨提示：
              <br><br>
              1、账户余额内的现金只能以邮局汇款方式提现；
              <br><br>
              2、每日提现次数不超过1次；
              <br><br>
              3、提现账户余额，需向邮局支付一定比例的手续费：200元以下2元，200元以上1%，最高不超过50元；
              <br><br>
              4、账户余额提现服务暂不支持国外和港澳台地区；
              <br><br>
              5、若您提现失败，邮局不退回相应的手续费；
              <br><br>
              6、提现金额不可大于可用余额；
              <br><br>
              7、申请提现后，本站网站处理时限是3个工作日，邮局处理时限是14个工作日；
              <br><br>
              8、常见提现失败原因：1）逾期退汇；2）地址不详；3）原地址查无此人
            </div>
          </div>
          <div v-show="index === '4-5'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              售后服务
            </div>
            <div class="contentDiv">
              本产品全国联保，享受三包服务，质保期为：十五天质保<br>
              本站商城服务承诺：<br>
              本站商城向您保证所售商品均为正品行货，自带机打发票，与商品一起寄送。凭质保证书及本站商城发票，可享受全国联保服务，与您亲临商场选购的商品享受相同的质量保证。<br>
              本站商城还为您提供具有竞争力的商品价格和免运费政策，请您放心购买！<br>
              声明:因厂家会在没有任何提前通知的情况下更改产品包装、产地或者一些附件，本司不能确保客户收到的货物与商城图片、产地、附件说明完全一致。只能确保为原厂正货！并且保证与当时市场上同样主流新品一致。若本商城没有及时更新，请大家谅解！
            </div>
          </div>
          <div v-show="index === '5-1'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              关于我们
            </div>
            <div class="contentDiv">
              本公司，国内领先的互联网＋物业 服务提供商，公司于2018年成立，公司注册资金500万元。本站以不断的知识积累和技术创新为前进动力，将社区O2O电商平台运营产品和服务做到国内领先，来满足客户不断发展的应用需求，为客户创造价值。
              <br><br>
              公司业务三大领域：
              <br><br>
              物业管理软件——本站智慧社区软件、本站智慧物业软件、本站车牌识别系统
              <br><br>
              智能硬件——人脸识别门禁、道闸、闸机
              <br><br>
              社区运营——社区O2O电商平台运营
            </div>
          </div>
          <div v-show="index === '5-2'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              常见问题
            </div>
            <div class="contentDiv">
              问：本站网站所售商品都是正品行货吗？有售后保修吗？
              <br><br>
              答：本站网站所售商品都是正品行货，均自带机打发票。凭本站网站发票，所有商品都可以享受生产厂家的全国联保服务。本站网站将严格按照国家三包政策，针对所售商品履行保修、换货和退货的义务。您也可以到本站网站任一分公司售后部享受售后服务。
              <br><br>
              问：购买的商品能开发票？如果是公司购买，可以开增值税发票吗？
              <br><br>
              答：本站网站所售商品都是正品行货，每张订单均自带中文机打的“商品专用发票”，此发票可用作单位报销凭证。发票会随包裹一同发出，发票金额含配送费金额。
              <br><br>
              企业客户在提供《一般纳税人证书》、《营业执照》、《税务登记证》、《开户许可证》四类证件复印件后，可向本站网站开取增值税发票，开好后，本站网站会以快递方式为您寄出。
              <br><br>
              问：各种库存状态是什么意思？下单多久后可以发货？
              <br><br>
              答：现货：库存有货，下单后会尽快发货，您可以立即下单；
              <br><br>
              在途：商品正在内部配货，一般1-2天有货，您可立即下单；
              <br><br>
              预订：商品正在备货，一般下单后2-20天可发货，您可立即下单；
              <br><br>
              无货：商品已售完，相应物流中心覆盖地区内的用户不能下单购买。
              <br><br>
              问：无货商品什么时候能到货？
              <br><br>
              答：无货商品的到货时间根据配货情况而不同，无法准确估计，但您可以使用“到货通知”功能，一旦商品有货，我们会通过电子邮件等方式通知您。
              <br><br>
              问：下单后何时可以收到货？
              <br><br>
              答：在商品有现货的情况下，下单后一般24小时内可收到货（郊区县配送时间可能会更长一些）；
              <br><br>
              其它地区用户，将根据您的收货地址及所选择的配送方式而不同，一般到货时间在1-7天（极偏远地区配送时间可能会更长一些）；
              <br><br>
              如果商品处于预订或在途状态，那么还应加上调配货时间。
              <br><br>
              问：快递费是多少？
              <br><br>
              答：凡选用“本站网站快递”或“快递运输”的会员即可享受免运费优惠。
              <br><br>
              问：在线支付支持哪些银行卡？支持大额支付吗？
              <br><br>
              答：我们为您提供几乎全部银行的银行卡及信用卡在线支付，只要您开通了“网上支付”功能，即可进行在线支付，无需手续费，实时到帐，方便快捷。（如客户原因取消订单退款，则需要客户承担1%平台手续费）如您订单金额较大，可以使用快钱支付中的招行、工行、建行、农行、广发进行一次性大额支付（一万元以下）。
              <br><br>
              问：在本站网站购物支持信用卡分期付款吗？如何申请？
              <br><br>
              答：本站网站目前支持中国银行、招商银行两家银行的信用卡分期付款，只要商品单价在500元以上，您即可点击“信用卡分期付款”按钮申请分期付款
              <br><br>
              问：上门提货、货到付款支持刷卡吗？周末可以自提吗？
              <br><br>
              答：本站网站全部自提点均支持现金及刷卡支付，绝大部分货到付款地区支持现金及刷卡支付
              <br><br>
              本站网站自提点营业时间一律为：周一至周日，09:00－19:00（如遇法定假日，以商城新闻公告为准）。
              <br><br>
              问：下单时可以指定送货时间吗？
              <br><br>
              答：可以，您下单时可以选择“只工作日送货(双休日、假日不用送)”、“工作日、双休日与假日均可送货”、“只双休日、假日送货(工作日不用送)”等时间类型，并选择是否提前电话确认。
              <br><br>
              另外，您还可以在订单备注里填写更具体的需求，我们会尽量满足您的要求。
              <br><br>
              问：哪些地区支持货到付款？
              <br><br>
              答：本站网站已在多个省市开通了货到付款(其它城市正陆续开通)，您可使用现金、移动POS机当面付款收货
              <br><br>
              问：收货时发现问题可以拒收吗？
              <br><br>
              答：在签收货物时如发现货物有损坏，请直接拒收退回我公司，相关人员将为您重新安排发货。
              <br><br>
              问：如果我刚刚下单商品就降价了，能给我补偿吗？
              <br><br>
              答：本站网站的商品价格随市场价格的波动每日都会有涨价、降价或者优惠等变化。如果下完订单后价格发生了变化，可到“我的本站网站”自主申请价格保护
              <br><br>
              问：下单后，我能做什么？
              <br><br>
              答：如果是在线支付方式，请您尽快完成付款，待付款被确认后我们会立即为您发货，
              <br><br>
              如果选择自提或货到付款，您可以进入“我的本站网站”，在“订单列表”中找到您的订单，然后可随时查看订单处理状态，做好收货或者上门自提的准备。
              <br><br>
              在您成功购物后，您还可以发表商品评价，向其他用户分享商品使用心得。
              <br><br>
              问：为什么我无法登陆商城？
              <br><br>
              答：首先要检查您的用户名、密码是否正确，确认您的浏览器是否支持COOKIE。
              <br><br>
              问：产品如何保修？
              <br><br>
              答：本站网站销售的商品都以商品说明中的保修承诺为准。
              <br><br>
              问：订单得到确认后我该做什么？
              <br><br>
              答：按照订单所提示的实际应汇款金额，汇款至该订单所在的公司账号内，汇款交易成功后，登陆“我的本站网站”查看您的订单，在订单中的“汇款备注”中输入您的相关汇款信息(例如：汇入行、汇入我司银行账号的实际金额、汇款日期和汇入账号、订单号等），等待我司财务人员确认汇款。
              <br><br>
              问：汇款确认后多久能够将货物发出？
              <br><br>
              答：正常情况下会在工作时间24-48小时内可以将您的货物发出。
              <br><br>
              问：非商品自身质量问题是否可以退货？
              <br><br>
              答：部分商品在不影响二次销售的情况下，加收一定的退货手续费，是可以办理退货的，详情请查看“退换货政策”
              <br><br>
              问：在哪能填写汇款信息？
              <br><br>
              答：首先要在本站网站首页的“会员登录”中输入用户名和密码进行登陆，登陆后点击“我的本站网站”，点击左侧的“订单中心”，即可查看到您所有的订单，点击汇款订单后面的“查看”，打开后下拉页面，有“付款信息未完成,请您尽快填写.”一项，直接在里面填写汇款信息，然后提交即可，相关人员在查收到您的汇款信息后会进行核实，无异议的汇款会在三个工作小时内确认完毕，如有问题，相关人员会电话与您联系。
              <br><br>
              问：怎样咨询商品的详细信息？
              <br><br>
              答：请您在该商品页面下方“购买咨询”处进行提问，相关商品管理员会为您回复。
              <br><br>
              问：在哪进行在线支付？
              <br><br>
              答：在本站网站首页的“会员登录”中输入用户名和密码进行登陆，登陆后点击“我的本站网站”，进入后点击左侧的“在线支付”，点击进入后就可以进行在线支付了。
              <br><br>
              问：工作时间？
              <br><br>
              答：客服中心受理热线电话及订单处理时间为7x24小时全天候服务；
              <br><br>
              自提接待时间为周一至周日9：00-19：00。
              <br><br>
              注：如遇国家法定节假日，则以本站网站新闻发布放假时间为准，请大家届时关注。
              <br><br>
              问：如何将退款打回银行卡？
              <br><br>
              答：在投诉中心留言相关信息，如银行卡的开户行(详细到支行）、开户姓名、卡号，相关人员会为您处理，退款周期视您的货物是否发出而定，如果货物未出库发出，退款会在三个工作日内完成；如果货物已发出，则需货物返回我司物流中心后为您办理退款。
              <br><br>
              问：商品包装问题？
              <br><br>
              答：我司所发送商品均由专人进行打包，商品在未签收前都由我司负责，如在收到商品时发现包装有破损或是其它方面问题，请直接致电我司客服*****，客服人员会帮您解决。
              <br><br>
              问：怎样申请高校代理送货？
              <br><br>
              答：在本站网站首页的“会员登录”中输入用户名和密码进行登陆，登陆后点击“我的本站网站”，进入后点击左侧的“个人资料”，在“所在学校”一栏中选择您所在的院校，(如没有您所在的院校，则说明您的学校暂未开通高校代理，您将无法选择高校代理送货），然后点击底部的“修改”，我司相关人员在收到申请后的24个工作小时内进行审核，审核通过后，您下单时就可以选择高校代理送货了，高校代理订单的运费按照钻石（双钻）会员普通快递运费标准收取，具体请您参照帮助中心中快递运输页面的“普通快递收取标准一览表”，货物由代理直接送达，货款由高校代理收取。
              <br><br>
              问：订单付款后，如果长时间未收到货，我是否可以申请办理退款？
              <br><br>
              答：非本站网站快递覆盖区域内，由第三方快递公司负责直接送达的订单，如圆通快递，自发货时间算起超过10天仍未收到货或收货地址超出第三方快递覆盖的区域，由第三方快递转邮政，如圆通转EMS等，自发货时间算起超过20天仍未收到货，可致电客服中心，由客服人员为您申请办理退款事宜。
              <br><br>
              问：如果我有问题或建议是否可以通过邮件向你们反馈？
              <br><br>
              答：可以。本站网站受理建议或投诉的邮箱是：service@ytash.com。
            </div>
          </div>
          <div v-show="index === '5-3'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              找回密码
            </div>
            <div class="contentDiv">
              忘记了帐户密码？
              <br><br>
              不用担心，本站网站提供找回密码服务，您点击 忘记密码 按照系统提示操作即可。
              <br><br>
              操作步骤详解如下：
              <br><br>
              1、在本站网站登陆页面，点击 忘记密码
              <br><br>
              2、按照提示，填写您在本站网站的注册邮箱及验证码
              <br><br>
              3、系统提示成功发送“密码重置”邮件，若您长时间未收到，可以点击“重新发送”
              <br><br>
              4、登陆您的个人邮箱，找到“本站网站新密码重置确认信本站网站新密码重置确认信”点击“设置新密码”
              <br><br>
              5、按照系统提示，设置新密码即可
              <br><br>
              温馨提示：
              <br><br>
              为了确保顾客注册信息的安全，本站网站只提供网上找回密码服务，若您忘记本站网站注册邮箱或是忘记注册邮箱的登陆密码，请 注册新用户
            </div>
          </div>
          <div v-show="index === '5-4'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              退订邮件/短信
            </div>
            <div class="contentDiv">
              本站网站保留通过邮件和短信的形式，对本网站注册、购物用户发送订单信息、促销活动等告知服务的权利。如果您在本站网站注册、购物，表明您已默示接受此项服务。
              <br><br>
              若您不希望接收本站网站的邮件，请在邮件下方输入您的E-mail地址自助完成退阅；
              <br><br>
              若您不希望接收本站网站的短信，请提供您的手机号码 联系客服 处理。
            </div>
          </div>
          <div v-show="index === '5-5'" style="margin-bottom: 20px">
            <div class="titleDivGuide">
              <i class="el-icon-info"></i>
              联系客服
            </div>
            <div class="contentDiv">
              欢迎来到保康益生（北京）科技有限公司
              <br><br>
              电话：010-65206455
              <br><br>
              邮箱：service@baokang.com
              <br><br>
              地址：北京市怀柔区北房镇幸福西街24号南侧一层（101-109室
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    props:{
      index:{
        type:String
      }
    },
    data() {
      return {
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath)
      },
      //切换菜单
      changeType(type){
        this.index = type
      }
    }
  }
</script>

<style lang="scss">
  .titleDivGuide{
    font-size: 16px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    border-bottom: 2px solid #F4F4F4;
    padding-bottom: 20px;
  }

  .contentDiv{
    font-size: 13px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
  }

</style>
