<template>
  <div style="width: 80%;margin-left: 10%">
    <div>
      <div style="margin-top: 20px;font-size: 20px">
        <!-- <i class="el-icon-star-off"></i>精选商家<span style="font-size: 14px"> | 精选细选的商家，品质和服务双重保障！</span> -->
        <!-- <div @click="toAdd()" class="titleDiv">立即入驻</div> -->
      </div>
      <div style="margin-top: 30px;margin-bottom: 30px">
        <el-container>
          <el-aside width="250px" style="background-color: #F4F4F4;border-right: 7px solid white">
            <el-image style="width: 150px;height: 120px;margin-left: 40px" src="http://81.70.76.82:8099//upload/seller/20210806024757564.png"></el-image>
            <div style="font-size: 13px;margin-left: 30px">
              <div class="leftDiv">洱全药品</div>
              <div class="leftDiv">
                综合评分:
                <el-rate
                  v-model="value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
                </el-rate>
              </div>
              <div class="leftDiv">销量:0件</div>
              <div class="leftDiv"  >商家地址：河北省 保定市 涞水县</div>
            </div>
          </el-aside>
          <el-main style="background-color: #F4F4F4;height: 274px">
            <el-row style="width: 85%;margin-top: 20px">
              <el-col :span="6">
                <div class="shoppingDetail" @click="goToGoodsDetail()">
                  <el-image class="formImage" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwMjQzOTkwLmpwZy93LzM4MC9oLzM4MAO0O0OO0O0O"></el-image>
                  <!-- <div class="priceDiv">￥31.50</div> -->
                  <div class="textDiv">左益 苯磺酸左氨氯地平片</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="shoppingDetail" @click="goToGoodsDetail()">
                  <el-image class="formImage" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwODM0NzA3LmpwZy93LzExMC9oLzExMAO0O0OO0O0O"></el-image>
                  <!-- <div class="priceDiv">￥30.00</div> -->
                  <div class="textDiv">施慧达 苯磺酸左氨氯地平片</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="shoppingDetail" @click="goToGoodsDetail()">
                  <el-image class="formImage" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwOTI2MjIyLmpwZy93LzExMC9oLzExMAO0O0OO0O0O"></el-image>
                  <!-- <div class="priceDiv">￥79.00</div> -->
                  <div class="textDiv">百普乐 培哚普利吲达帕胺片 20片</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="shoppingDetail" @click="goToGoodsDetail()">
                  <el-image class="formImage" src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMDMxNDQyLmpwZy93LzExMC9oLzExMAO0O0OO0O0O"></el-image>
                  <!-- <div class="priceDiv">￥33.50</div> -->
                  <div class="textDiv">拜新同 硝苯地平控释片</div>
                </div>
              </el-col>
            </el-row>
            <!-- <div class="inDiv" @click="toDetail()">进入店铺</div> -->
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        value: 5.0
      }
    },
    methods: {
      //跳转申请入驻页
      toAdd(){
        this.$emit('changeType',6)
      },
      //进入商家
      toDetail(){
        this.$emit('changeType',8)
      },
      goToGoodsDetail(){
        this.$emit('changeType',13)
      }
    }
  }
</script>

<style lang="scss">
  .formImage{
    width: 120px;
    height: 120px;
    margin-left: 60px;
  }

  .priceDiv{
    text-align: center;
    margin-left: 60px;
    background-color: #4D4D4D;
    width: 120px;
    color: white;
  }

  .textDiv{
    font-size: 14px;
    margin-left: 40px;
    margin-top:30px;
    color: #3A3C84;
  }

  .inDiv{
    font-size:16px;
    float: right;
    width: 30px;
    height:150px;
    padding-left:10px;
    padding-top: 10px;
    color: white;
    background-color: #3A3C84;
    margin-top: -200px;
    cursor:pointer;
  }

  .titleDiv{
    float: right;
    text-align:center;
    font-size: 14px;
    background-color:#3A3C84;
    color: white;
    width: 100px;
    height: 30px;
    padding-top: 10px;
    cursor:pointer;
  }

  .leftDiv{
    font-size: 14px;
    padding-bottom: 5px;
  }

  .shoppingDetail{
    cursor:pointer;
  }
</style>
