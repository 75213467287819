<template >
	<div >
		<section >
			<!--顶部菜单开始-->
			<nav class="site-topbar" >
				<div class="wraper">
					<ul class="nav-left">
						<li><a href="#">

								嗨！欢迎来到大白乐家（北京）健康科技有限公司
<!--								<span style="margin-left: 20px;" @click="toPage(2)">请登录</span>-->
<!--								<span style="margin-left: 20px;color:red" @click="toPage(3)">免费注册</span>-->
							</a></li>
					</ul>
					<ul class="nav-right">
						<!-- <li><a href="#" @click="toPage(5)">我的商城</a></li> -->
						<li><a href="#" @click="toPageShopping('5-5',10)">客服咨询</a></li>
						<!-- <li><a href="#" @click="toPage(7)">商家入驻</a></li> -->
						<!-- <li class="buyCar">
							<a class="i_car" href="#" @click="toPage(11)">购物车（0）</a>
							<div class="content"></div>
						</li> -->
					</ul>
				</div>
			</nav>
			<!--顶部菜单结束-->
			<!--头部开始-->
			<header id="header" class="site-header">
				<div class="wraper">
					<hgroup class="header-left shoppingDD" @click="toPage(1)">

						<img style="width: 180px;" src="http://dabaichina.com/img/logo.jpg" />
					</hgroup>



					<div class="header-right">
						<input type="text" class="search" list="search-record" placeholder="商品名称" name="search">
						<button class="btn" style="cursor:pointer" @click="toPage(12)">搜索</button>
<!--						<ul>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--							<li>某某药品123123123<span>约有999件</span></li>-->
<!--						</ul>-->
					<div class="btn-phone">
                    <i class="el-icon-phone-outline
"></i>     010-65206455
					</div>
					</div>

				</div>
			</header>
			<!--头部结束-->




<nav  class="site-topbar-group">
		<div class="wraper">
			<ul class="nav-left-group">
<!--				<li><a href="#" @click="toPage(12)">全部商品分类</a></li>-->
				<li><a href="#" @click="toPage(1)">首页</a></li>
		<!-- 		<li><a href="#" @click="toPage(5)">商家管理</a></li>
				<li><a href="#" @click="toPage(7)">入驻商家</a></li> -->
				<li><a href="#" @click="toPage(9)">信息服务</a></li>
				<li><a href="#" @click="toPage(15)">产品服务</a></li>
				<li><a href="#" @click="toPage(16)">公司介绍</a></li>
				
				<li><a href="#" @click="toPageShopping('1',14)">付费文章</a></li>
			</ul>
		</div>
	</nav>



 <indexCenter v-show="pageType===1" ref="indexCenter" @changeType="toPage" @changeType1="toPageShopping" @changeType2="toPageDetail"/>

  <loginPage v-show="pageType===2" ref="loginPage" @changeType="toPage"/>

  <registerPage v-show="pageType===3" ref="registerPage" @changeType="toPage" @changeType1="toPageShopping"/>

  <resetPassword v-show="pageType===4" ref="resetPassword"/>

  <merchantsIn v-show="pageType===5" ref="merchantsIn" @changeType="toPage"/>

  <merchantsAdd v-show="pageType===6" ref="merchantsAdd"/>

  <settledMerchants v-show="pageType===7" ref="settledMerchants" @changeType="toPage"/>

  <merchantsDetail v-show="pageType===8" ref="merchantsDetail" @changeType="toPage"/>

  <informationService  v-show="pageType===9" ref="informationService"/>
  <productServices  v-show="pageType===15" ref="productServices"/>
  <companyServices  v-show="pageType===16" ref="companyServices"/>

  <shoppingGuide  v-show="pageType===10" v-bind:index="parentIndex" ref="shoppingGuide"/>

  <cartPage  v-show="pageType===11" ref="cartPage" v-bind:detail="detail" @changeType="toPage" />

    <goodsClass  v-show="pageType===12" ref="goodsClass" @changeType="toPage"/>

        <detail  v-show="pageType===13" ref="detail" v-bind:id="goodsId" @changeType="toPage" @changeType3="toPageCart"/>

			<paidArticles v-show="pageType===14" v-bind:index="parentIndex" ref="paidArticles" @changeType="toPage"></paidArticles>

			<footer id="footer" class="" style="background-color: #454545;color:white">

<!--				<section class="wraper footer-middle" >-->

<!--					<section class="footer-left menu-columns" style="margin-left: 20%;">-->
<!--						<ul class="menu">-->
<!--							<li>-->
<!--								<dl>-->
<!--									<dt class="shoppingDT">购物指南</dt>-->
<!--									&lt;!&ndash; <dd class="shoppingDD" @click="toPageShopping('1-2',10)">购物流程</dd> &ndash;&gt;-->
<!--									<dd class="shoppingDD" @click="toPageShopping('1-3',10)">会员制度</dd>-->
<!--									&lt;!&ndash; <dd class="shoppingDD" @click="toPageShopping('1-4',10)">积分说明</dd> &ndash;&gt;-->
<!--									&lt;!&ndash; <dd class="shoppingDD" @click="toPageShopping('1-5',10)">交易条款</dd> &ndash;&gt;-->
<!--								</dl>-->
<!--							</li>-->
<!--						</ul>-->
<!--&lt;!&ndash; 						<ul class="menu">-->
<!--							<li>-->
<!--								<dl>-->
<!--									<dt class="shoppingDT">支付帮助</dt>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('2-1',10)">货到付款</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('2-2',10)">在线支付</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('2-3',10)">余额支付</dd>-->
<!--								</dl>-->
<!--							</li>-->
<!--						</ul> &ndash;&gt;-->
<!--&lt;!&ndash; 						<ul class="menu">-->
<!--							<li>-->
<!--								<dl>-->
<!--									<dt class="shoppingDT">配送帮助</dt>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('3-1',10)">加急快递</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('3-2',10)">商品验货与签收</dd>-->
<!--								</dl>-->
<!--							</li>-->
<!--						</ul> &ndash;&gt;-->
<!--						<ul class="menu">-->
<!--							<li>-->
<!--								<dl>-->
<!--									<dt class="shoppingDT">售后服务</dt>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('4-1',10)">换货说明</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('4-2',10)">退货说明</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('4-3',10)">退/换货注意事项</dd>-->
<!--									&lt;!&ndash; <dd class="shoppingDD" @click="toPageShopping('4-4',10)">余额的使用与提现</dd> &ndash;&gt;-->
<!--								</dl>-->
<!--							</li>-->
<!--						</ul>-->
<!--						<ul class="menu">-->
<!--							<li>-->
<!--								<dl>-->
<!--									<dt class="shoppingDT">帮助信息</dt>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('5-1',10)">关于我们</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('5-2',10)">常见问题</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('5-3',10)">找回密码</dd>-->
<!--									<dd class="shoppingDD" @click="toPageShopping('5-4',10)">退订邮件/短信</dd>-->
<!--								</dl>-->
<!--							</li>-->
<!--						</ul>-->

<!--					</section>-->
<!--				</section>-->

				<footer class="footer-bottom"  style="background-color: #444A50;color:white;text-align: center;">
					<div class="wraper">

							<p class="" style="margin-top: 10px;" >
								Copyright © 2021 大白乐家（北京）健康科技有限公司 © 版权所有
								<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备16069121号-2</a>
							</p>
							<!-- <p class=""  style="margin-top: 10px;" >
								互联网药品信息服务资格证书：（京）-经营性-2022-0020
							</p> -->
							<p class=""  style="margin-top: 10px;" >
								公司地址：北京市怀柔区北房镇幸福西街24号南侧一层（101-109室） 电话：010-65206455
							</p>
							<p class=""  style="margin-top: 10px;" >
							用户投诉与建议邮箱：service@baokang.com | 电话：010-65206455
							</p>
					</div>
				</footer>
			</footer>
		</section>
	</div>
</template>

<script>
  import indexCenter from "../indexCenter/index.vue";
  import loginPage from "../mall/loginPage/index.vue";
  import registerPage from "../mall/registerPage/index.vue";
  import resetPassword from "../mall/resetPassword/index.vue";
  import settledMerchants from "../mall/settledMerchants/index.vue";
  import merchantsIn from "../mall/merchantsIn/index.vue";
  import merchantsAdd from "../mall/merchantsAdd/index.vue";
  import merchantsDetail from "../mall/merchantsDetail/index.vue";
  import informationService from "../mall/informationService/index.vue";
  import productServices from "../mall/productServices/index.vue";
  import companyServices from "../mall/companyServices/index.vue";
  
  import shoppingGuide from "../mall/shoppingGuide/index.vue";
  import cartPage from "../cart/index.vue";
  import goodsClass from "../goodsClass/index.vue";
  import detail from "../detail/index.vue";
  import paidArticles from "../mall/paidArticles/index.vue";


export default {
   components: {indexCenter,loginPage,registerPage,resetPassword,settledMerchants,merchantsIn,merchantsAdd,merchantsDetail,informationService,productServices,companyServices,cartPage,goodsClass,detail,paidArticles},
	data() {
		return {
pageType:1,
			parentIndex:"5-5",
			goodsId:"1",
      detail:"1"
		}
	},
	methods: {
		//公共页面切换方法
		toPage(num){
			window.scroll(0,0)
			this.pageType = num
		},
		//购物指南跳转方法
		toPageShopping(type,num){
			window.scroll(0,0)
			this.parentIndex = type
			this.pageType = num
		},
		//商品详情
		toPageDetail(id,num){
			window.scroll(0,0)
			console.log("id:"+id)
			console.log("num:"+num)
			this.goodsId = id
			this.pageType = num
		},
    toPageCart(detail,num){
      window.scroll(0,0)
			console.log("detail:"+detail)
			console.log("num:"+num)
			this.detail = detail
			this.pageType = num
    }
	}
}
</script>

<style>

@import '../../common/styles/base.css';
@import '../../common/styles/style.css';
@import '../../common/styles/media_index.css';
@import '../../common/styles/mod.css';
</style>

<style lang="scss">
	.shoppingDT{
		margin-bottom: 10px;
		font-weight: bold;
	}
	.shoppingDD{
		cursor:pointer;
		padding-bottom: 10px;
	}
</style>
