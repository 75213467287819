<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" class="login-form">
      <h3 class="title">找回密码</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="请输入正确的用户名">
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" type="text" auto-complete="off" placeholder="请输入正确的邮件地址">
        </el-input>
      </el-form-item>
      <el-form-item prop="password" v-show="type === true">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="loginForm.code" type="text" auto-complete="off" placeholder="手机验证码"/>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" size="small">获取验证码</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button size="medium" type="primary" style="width: 100%">
          <span>找回密码</span>
        </el-button>
      </el-form-item>
      <div style="display: flex">
        <div class="loginDiv">
          <span class="loginSpan" v-show="type === false" @click="changeType()">手机短信重置密码</span>
          <span class="loginSpan" v-show="type === true" @click="changeType()">电子邮件重置密码</span>
        </div>
      </div>
    </el-form>
  </div>

</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        loginForm:{},
        type:false
      }
    },
    methods: {
      //改变验证方式
      changeType(){
        this.type = !this.type
      }
    }
  }
</script>

<style lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../../../assets/loginBg.jpg");
    background-size: cover;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 21px;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    height: 450px;
    padding: 25px 25px 5px 25px;
    margin-left: 50%;
    margin-top: 5%;
    margin-bottom: 5%;

    .el-input {
      height: 38px;
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 38px;
  }
</style>
