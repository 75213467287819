<template >
	<div >


<!--<div style=" position:fixed; left:0; top:45%">-->

<!--<div class="leftGroup" @click="goTolocaltion(1)">热销<br/>商品</div>-->
<!--<div class="leftGroup" @click="goTolocaltion(2)">心脑<br/>血管</div>-->
<!--<div class="leftGroup" @click="goTolocaltion(3)">皮肤<br/>用药</div>-->
<!--<div class="leftGroup" @click="goTolocaltion(4)">肝胆<br/>胰类</div>-->
<!--&lt;!&ndash; <div class="leftGroup" @click="goTolocaltion(5)">医疗<br/>器械</div> &ndash;&gt;-->
<!--<div class="leftGroup" @click="goTolocaltion(6)">返回<br/>顶部</div>-->
<!--</div>-->

			<!--中部区域开始-->
			<div class="wraper">

				<section id="main" class="site-main clear_bottom">
<!--					<aside class="main-left">-->
<!--						<ul class="category-list">-->
<!--							<li>-->
<!--								<a href="#" @click="goToGoodsClass()"><span>心脑血管</span> </a>-->
<!--								<div class="content">-->
<!--									<ul class="good-list">-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1MzE1ODA2LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">可定 瑞舒伐他汀钙片</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQxNzE3OTYwLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">信邦 益心舒胶囊</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwOTI2MjIyLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">百普乐 培哚普利吲达帕胺片</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM0ODI3NzkwLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">来适可 氟伐他汀钠缓释片</li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</li>-->
<!--							<li>-->
<!--								<a href="#" @click="goToGoodsClass()"><span>皮肤用药</span> </a>-->
<!--								<div class="content">-->
<!--									<ul class="good-list">-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDkxNTIzMzczLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">鹏鹞 炉甘石洗剂</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMTAxMzAxNzg4LnBuZy93LzE3MC9oLzE3MAO0O0OO0O0O">百多邦 莫匹罗星软膏</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQ1NTU0ODQ0LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">美宝 湿润烧伤膏</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDUzNDQyMzEzLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">【维价正品】诺金 湿毒清片</li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</li>-->
<!--							<li>-->
<!--								<a href="#" @click="goToGoodsClass()"><span>肝胆乙类</span> </a>-->

<!--								<div class="content">-->
<!--									<ul class="good-list">-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUxNTUwOTM0LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">贺维力 阿德福韦酯片</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDU1NTIyNjgzLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">修正 复方胆通胶囊</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQzNzM0Njc4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">奥皇 强肝胶囊</li>-->
<!--										<li @click="goToGoodsDetail()"><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUwNTA3Nzg4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O">恩甘定 恩替卡韦胶囊</li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</li>-->
<!--							&lt;!&ndash; <li>-->
<!--								<a href="#" @click="goToGoodsClass()"><span>医疗器械</span> </a>-->

<!--								<div class="content">-->
<!--									<ul class="good-list">-->
<!--										<li @click="goToGoodsDetail('2')"><img src="../../assets/medical/medical1.png">肺活量计</li> &ndash;&gt;-->
<!--&lt;!&ndash;										<li @click="goToGoodsDetail('3')"><img src="../../assets/medical/medical2.jpg">呼吸过滤器</li>&ndash;&gt;-->
<!--&lt;!&ndash;										<li @click="goToGoodsDetail('4')"><img src="../../assets/medical/medical3.jpg">压电式雾化器</li>&ndash;&gt;-->
<!--&lt;!&ndash; 									</ul>-->
<!--								</div>-->
<!--							</li>-->
<!-- &ndash;&gt;-->
<!--						</ul>-->
<!--					</aside>-->
					<!--<div id="test">123</div>-->
					<section class="main-right">
						<div class="slider">
							<ul class="contain" style="margin-left: -30%">
								<li class="item"><a href="#" alt=""><img
											src="http://dabaitest.dabai999.com/banner/1.jpg"></a></li>
												<li class="item"><a href="#" alt=""><img
											src="http://dabaitest.dabai999.com/banner/2.jpg"></a></li>
												<li class="item"><a href="#" alt=""><img
											src="http://dabaitest.dabai999.com/banner/3.jpg"></a></li>
												<li class="item"><a href="#" alt=""><img
											src="http://dabaitest.dabai999.com/banner/4.jpg"></a></li>
												<li class="item"><a href="#" alt=""><img
											src="http://dabaitest.dabai999.com/banner/5.jpg"></a></li>


							</ul>
<!--							<img  src="../../assets/join.jpg" style="width:230px; position: absolute; top:0; right: 0%;"/>-->
						</div>
					</section>
				</section>
			</div>

					<!--最新资讯开始-->
		<section id="sku" class="wraper m-box mi-sku">
			<input type="radio" id="sku-s1" class="sku-switch s1" name="sku-switch" hidden checked>
			<input type="radio" id="sku-s2" class="sku-switch s2" name="sku-switch" hidden>
			<header class="m-hd">
				<h2 class="m-hdL">最新资讯</h2>
			</header>
			<div class="m-box">
				<section class="span20 m-slide">
					<div class="m-slide-contain m-s2">
						<div class="m-slide-item">
							<ul class="m-cols m-col-4">
								<li class="col sku-item shoppingDetail" @click="goToArticlesDetail('detail1')">
									<dl class="row">
										<dt><img style="width: 250px" src="../../assets/ai1.jpg"></dt>
										<dd class="name">人工智能技术在医药研发中的应用</dd>
									</dl>
								</li>
								<li class="col sku-item shoppingDetail" @click="goToArticlesDetail('detail2')">
									<dl class="row">
										<dt><img style="width: 250px" src="../../assets/ai2.jpg"></dt>
										<dd class="name">人工智能技术在医学影像产业的应用与思考</dd>
									</dl>
								</li>
								<li class="col sku-item shoppingDetail" @click="goToArticlesDetail('detail3')">
									<dl class="row">
										<dt><img style="width: 250px" src="../../assets/ai3.jpg"></dt>
										<dd class="name">抗病毒药物的现状与发展</dd>
									</dl>
								</li>
								<li class="col sku-item shoppingDetail" @click="goToArticlesDetail('detail4')">
									<dl class="row">
										<dt><img style="width: 250px" src="../../assets/ai4.jpg"></dt>
										<dd class="name">高效液相色谱法手性固定相分手性药物研究进展</dd>
									</dl>
								</li>
							</ul>
						</div>
						<div class="m-slide-item" />
					</div>
				</section>
			</div>

		</section>
		<!--最新资讯-->
<!--			&lt;!&ndash;热销推荐开始&ndash;&gt;-->
<!--			<section id="sku" class="wraper m-box mi-sku">-->
<!--				<input type="radio" id="sku-s1" class="sku-switch s1" name="sku-switch" hidden checked>-->
<!--				<input type="radio" id="sku-s2" class="sku-switch s2" name="sku-switch" hidden>-->
<!--				<header class="m-hd">-->
<!--					<h2 class="m-hdL">热销推荐</h2>-->
<!--				</header>-->
<!--				<div class="m-box">-->
<!--					<section class="span20 m-slide">-->
<!--						<div class="m-slide-contain m-s2">-->
<!--							<div class="m-slide-item">-->
<!--								<ul class="m-cols m-col-5">-->
<!--									<li class="col sku-item shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<dl class="row">-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM0OTIzNzM4LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">富利他之 普伐他汀钠片</dd>-->
<!--											<dd class="desc">20mg*10片</dd>-->
<!--											&lt;!&ndash; <dd class="price">30.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</li>-->
<!--									<li class="col sku-item shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<dl class="row">-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1MTQ0OTEzLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">汉王 强力定眩片</dd>-->
<!--											<dd class="desc">0.35g*60片</dd>-->
<!--											&lt;!&ndash; <dd class="price">33.50元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</li>-->
<!--									<li class="col sku-item shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<dl class="row">-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQ0NDExNTUzLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">天力士 复方丹参滴丸</dd>-->
<!--											<dd class="desc">0.25g*30粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">35.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</li>-->
<!--									<li class="col sku-item shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<dl class="row">-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1NDU0MzU5LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">来适可 氟伐他汀钠胶囊</dd>-->
<!--											<dd class="desc">40mg*7粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">26.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</li>-->
<!--									<li class="col sku-item shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<dl class="row">-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQxMjQyNjM5LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">天士力 复方丹参滴丸</dd>-->
<!--											<dd class="desc">27mg*180丸</dd>-->
<!--											&lt;!&ndash; <dd class="price">28.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</li>-->
<!--								</ul>-->
<!--							</div>-->
<!--							<div class="m-slide-item" />-->
<!--						</div>-->
<!--					</section>-->
<!--				</div>-->

<!--			</section>-->
<!--			&lt;!&ndash;热销单品结束&ndash;&gt;-->
<!--			<div class="gray-section">-->
<!--				&lt;!&ndash; 心脑血管开始&ndash;&gt;-->
<!--				<section class="wraper mi-hard clear_bottom" id="hard">-->
<!--					<header class="m-hd">-->
<!--						<h2 class="m-hdL">心脑血管</h2>-->
<!--						<div class="m-hdR">-->
<!--							<div class="scan" style="cursor:pointer" @click="goToGoodsClass()">-->
<!--								<span>查看全部</span>-->
<!--								<a href="#" class="icon">&gt;</a>-->
<!--							</div>-->
<!--						</div>-->
<!--					</header>-->
<!--					<section class="m-box line2">-->
<!--						<div class="span4">-->
<!--							<div class="row main-pic">-->
<!--								<img src="http://81.70.76.82:8099/views/quanyaotong/skin/default/image/floor.png">-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="span16">-->
<!--							<ul class="m-cols m-col-4">-->
<!--								<li class="col">-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip orange">免邮件</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1MzE1ODA2LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">可定 瑞舒伐他汀钙片</dd>-->
<!--											<dd class="desc">10mg*7片*4板</dd>-->
<!--											&lt;!&ndash; <dd class="price">146.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip blue">有赠品</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQxNzE3OTYwLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">信邦 益心舒胶囊</dd>-->
<!--											<dd class="desc">0.4g*36粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">34.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="col">-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip orange">免邮件</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwOTI2MjIyLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">百普乐 培哚普利吲达帕胺片</dd>-->
<!--											<dd class="desc">20片</dd>-->
<!--											&lt;!&ndash; <dd class="price">79.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip blue">有赠品</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM0ODI3NzkwLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">来适可 氟伐他汀钠缓释片</dd>-->
<!--											<dd class="desc">80mg*7片</dd>-->
<!--											&lt;!&ndash; <dd class="price">34.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="col">-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip orange">免邮件</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQwODU5NTk4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">红花牌 血府逐瘀胶囊</dd>-->
<!--											<dd class="desc">0.4g*24粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">19.5元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip blue">有赠品</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDM1MzQxOTExLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">优克 降脂通脉胶囊</dd>-->
<!--											<dd class="desc">0.5g*12粒*2板</dd>-->
<!--											&lt;!&ndash; <dd class="price">21.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="col">-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip orange">免邮件</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxODE0NTk4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">吉加 厄贝沙坦片</dd>-->
<!--											<dd class="desc">0.15g*7片</dd>-->
<!--											&lt;!&ndash; <dd class="price">8.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--									<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--										<span class="tip blue">有赠品</span>-->
<!--										<dl>-->
<!--											<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMDMxNDQyLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--											<dd class="name">拜新同 硝苯地平控释片</dd>-->
<!--											<dd class="desc">30mg*7片</dd>-->
<!--											&lt;!&ndash; <dd class="price">33.00元</dd> &ndash;&gt;-->
<!--										</dl>-->
<!--									</div>-->
<!--								</li>-->
<!--							</ul>-->
<!--						</div>-->
<!--					</section>-->
<!--				</section>-->
<!--				&lt;!&ndash;心脑血管结束&ndash;&gt;-->

<!--				&lt;!&ndash;皮肤用药开始&ndash;&gt;-->
<!--				<section class="wraper mi-match clear_bottom" id="match">-->
<!--					<input type="radio" id="match-s1" class="match-switch s1" name="match-switch" hidden checked>-->
<!--					<input type="radio" id="match-s2" class="match-switch s2" name="match-switch" hidden>-->
<!--					<input type="radio" id="match-s3" class="match-switch s3" name="match-switch" hidden>-->
<!--					<input type="radio" id="match-s4" class="match-switch s4" name="match-switch" hidden>-->
<!--					<header class="m-hd">-->
<!--						<h2 class="m-hdL">皮肤用药</h2>-->
<!--						<div class="m-hdR">-->
<!--							<div class="scan" style="cursor:pointer" @click="goToGoodsClass()">-->
<!--								<span>查看全部</span>-->
<!--								<a href="#" class="icon">&gt;</a>-->
<!--							</div>-->
<!--						</div>-->
<!--					</header>-->
<!--					<section class="m-box line2  clear_top  clear_bottom">-->
<!--						<div class="span4">-->
<!--							<div class="row main-pic">-->
<!--								<img src="http://81.70.76.82:8099/views/quanyaotong/skin/default/image/floor.png">-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="span16">-->
<!--							&lt;!&ndash;滑动切换的模块&ndash;&gt;-->
<!--							<div class="m-slide">-->
<!--								<div class="m-slide-contain m-s4">-->
<!--									<div class="m-slide-item">-->
<!--										<ul class="m-cols m-col-4">-->
<!--											<li class="col">-->
<!--													<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDkxNTIzMzczLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--														<dd class="name">鹏鹞 炉甘石洗剂</dd>-->
<!--														<dd class="desc">100ml</dd>-->
<!--														&lt;!&ndash; <dd class="price">12.90元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMTAxMzAxNzg4LnBuZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">百多邦 莫匹罗星软膏</dd>-->
<!--											<dd class="desc">2%:5g</dd>-->
<!--											&lt;!&ndash; <dd class="price">17.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQ1NTU0ODQ0LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">美宝 湿润烧伤膏</dd>-->
<!--											<dd class="desc">20g</dd>-->
<!--											&lt;!&ndash; <dd class="price">43.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--															<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDUzNDQyMzEzLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">【维价正品】诺金 湿毒清片</dd>-->
<!--											<dd class="desc">0.6g*30片</dd>-->
<!--											&lt;!&ndash; <dd class="price">19.80元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--															<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMTAwMTI0NjAyLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">康王 复方酮康唑发用洗剂</dd>-->
<!--											<dd class="desc">50ml</dd>-->
<!--											&lt;!&ndash; <dd class="price">32.5元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDkxNTQ2MjI4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">仁和 利多卡因氯己定气雾剂</dd>-->
<!--											<dd class="desc">50g/瓶</dd>-->
<!--											<dd class="price">17.50元</dd>-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDUwMTE0NDIwLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">【十年老店】易孚 人表皮生长因子凝胶</dd>-->
<!--											<dd class="desc">5万IU(100μg)/10g/支</dd>-->
<!--											&lt;!&ndash; <dd class="price">46.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()" content="吃了一口气上18层楼,倍棒！！" from="来自于峰峰的评价">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDQ1NjI1NTc5LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">恒霸 伤科灵喷雾剂</dd>-->
<!--											<dd class="desc">60ml/盒</dd>-->
<!--											&lt;!&ndash; <dd class="price">43.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--										</ul>-->
<!--									</div>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</section>-->
<!--				</section>-->
<!--				&lt;!&ndash;皮肤用药结束&ndash;&gt;-->

<!--				&lt;!&ndash;肝胆胰类开始&ndash;&gt;-->
<!--				<section class="wraper mi-parts" id="parts">-->
<!--					<input type="radio" id="parts-s1" class="parts-switch s1" name="parts-switch" hidden checked>-->
<!--					<input type="radio" id="parts-s2" class="parts-switch s2" name="parts-switch" hidden>-->
<!--					<input type="radio" id="parts-s3" class="parts-switch s3" name="parts-switch" hidden>-->
<!--					<input type="radio" id="parts-s4" class="parts-switch s4" name="parts-switch" hidden>-->
<!--					<header class="m-hd">-->
<!--						<h2 class="m-hdL">肝胆胰类</h2>-->
<!--						<div class="m-hdR">-->
<!--							<div class="scan" style="cursor:pointer" @click="goToGoodsClass()">-->
<!--								<span>查看全部</span>-->
<!--								<a href="#" class="icon">&gt;</a>-->
<!--							</div>-->
<!--						</div>-->
<!--					</header>-->
<!--					<section class="m-box line2">-->
<!--						<div class="span4">-->
<!--							<div class="main-pic">-->
<!--								<img src="http://81.70.76.82:8099/views/quanyaotong/skin/default/image/floor.png">-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="span16">-->
<!--							<div class="m-slide">-->
<!--								<div class="m-slide-contain m-s4">-->
<!--									<div class="m-slide-item">-->
<!--										<ul class="m-cols m-col-4">-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUxNTUwOTM0LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">贺维力 阿德福韦酯片</dd>-->
<!--											<dd class="desc">10mg*14片</dd>-->
<!--											&lt;!&ndash; <dd class="price">147.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDU1NTIyNjgzLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">修正 复方胆通胶囊</dd>-->
<!--											<dd class="desc">36粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">32.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQzNzM0Njc4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">奥皇 强肝胶囊</dd>-->
<!--											<dd class="desc">0.4g*40粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">26.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUwNTA3Nzg4LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">恩甘定 恩替卡韦胶囊</dd>-->
<!--											<dd class="desc">0.5mg*7粒</dd>-->
<!--											&lt;!&ndash; <dd class="price">29.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUzMjQ4OTg2LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">嘉应 消炎利胆片</dd>-->
<!--											<dd class="desc">0.25g*100片</dd>-->
<!--											&lt;!&ndash; <dd class="price">19.90元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDUxNjIwNTI0LmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">和恩 恩替卡韦分散片</dd>-->
<!--											<dd class="desc">0.5mg*7片*2板/盒</dd>-->
<!--											&lt;!&ndash; <dd class="price">21.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQ0NzM4ODYzLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--										<dd class="name">玉药 三七脂肝丸(浓缩水蜜丸)</dd>-->
<!--											<dd class="desc">5g*18袋(0.65g/10丸)</dd>-->
<!--											&lt;!&ndash; <dd class="price">258.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail()">-->
<!--													<span class="tip orange">免邮件</span>-->
<!--													<dl>-->
<!--														<dt><img src="http://81.70.76.82:8099/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA75eL8z5I6w2M7jcE5w8O4D6A85cMDQzNTI2NzQxLmpwZy93LzE3MC9oLzE3MAO0O0OO0O0O"></dt>-->
<!--														<dd class="name">金古密 卵磷脂</dd>-->
<!--														<dd class="desc">120片*3瓶/盒</dd>-->
<!--														&lt;!&ndash; <dd class="price">1088.00元</dd> &ndash;&gt;-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li>-->
<!--										</ul>-->
<!--									</div>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</section>-->
<!--				</section>-->
<!--				&lt;!&ndash;肝胆胰类结束&ndash;&gt;-->

<!--				&lt;!&ndash;医疗器械&ndash;&gt;-->
<!--&lt;!&ndash; 				<section class="wraper mi-parts" id="medical">-->
<!--					<input type="radio" id="medical-m1" class="parts-switch s1" name="parts-switch" hidden checked>-->
<!--					<header class="m-hd">-->
<!--						<h2 class="m-hdL">医疗器械</h2>-->
<!--					</header>-->
<!--					<section class="m-box line2" style="height: 310px">-->
<!--						<div class="span4">-->
<!--							<div class="main-pic">-->
<!--								<img src="http://81.70.76.82:8099/views/quanyaotong/skin/default/image/floor.png">-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="span16">-->
<!--							<div class="m-slide" style="height: 310px">-->
<!--								<div class="m-slide-contain m-s4">-->
<!--									<div class="m-slide-item">-->
<!--										<ul class="m-cols m-col-4">-->
<!--											<li class="col">-->
<!--												<div class="row shoppingDetail" @click="goToGoodsDetail('2')" style="height: 310px">-->
<!--													<span class="tip blue">有赠品</span>-->
<!--													<dl>-->
<!--														<dt><img src="../../assets/medical/medical1.png"></dt>-->
<!--														<dd class="name">肺活量计</dd>-->
<!--														<dd class="desc">A1</dd>-->
<!--														<dd class="price">1980.00元</dd>-->
<!--													</dl>-->
<!--												</div>-->
<!--											</li> &ndash;&gt;-->
<!--&lt;!&ndash;											<li class="col">&ndash;&gt;-->
<!--&lt;!&ndash;												<div class="row shoppingDetail" @click="goToGoodsDetail('3')" style="height: 310px">&ndash;&gt;-->
<!--&lt;!&ndash;													<span class="tip blue">有赠品</span>&ndash;&gt;-->
<!--&lt;!&ndash;													<dl>&ndash;&gt;-->
<!--&lt;!&ndash;														<dt><img src="../../assets/medical/medical2.jpg"></dt>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="name">呼吸过滤器</dd>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="desc">MicroGard IIB</dd>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="price">25.00元</dd>&ndash;&gt;-->
<!--&lt;!&ndash;													</dl>&ndash;&gt;-->
<!--&lt;!&ndash;												</div>&ndash;&gt;-->
<!--&lt;!&ndash;											</li>&ndash;&gt;-->
<!--&lt;!&ndash;											<li class="col">&ndash;&gt;-->
<!--&lt;!&ndash;												<div class="row shoppingDetail" @click="goToGoodsDetail('4')" style="height: 310px">&ndash;&gt;-->
<!--&lt;!&ndash;													<span class="tip blue">有赠品</span>&ndash;&gt;-->
<!--&lt;!&ndash;													<dl>&ndash;&gt;-->
<!--&lt;!&ndash;														<dt><img src="../../assets/medical/medical3.jpg"></dt>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="name">压电式雾化器</dd>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="desc">HL100F</dd>&ndash;&gt;-->
<!--&lt;!&ndash;														<dd class="price">1200.00元</dd>&ndash;&gt;-->
<!--&lt;!&ndash;													</dl>&ndash;&gt;-->
<!--&lt;!&ndash;												</div>&ndash;&gt;-->
<!--&lt;!&ndash;											</li>&ndash;&gt;-->
<!--		&lt;!&ndash; 									<li class="col">-->
<!--												<div class="row row_half scan-more" style="height: 150px;cursor:pointer">-->
<!--													<div class="scan-moreL" @click="goToGoodsClass()">-->
<!--														<b>浏览更多</b>-->
<!--														<small>热门</small>-->
<!--													</div>-->
<!--													<div class="scan-moreR icon"></div>-->
<!--												</div>-->
<!--											</li>-->
<!--										</ul>-->
<!--									</div>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--									<div class="m-slide-item"/>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</section>-->
<!--				</section> &ndash;&gt;-->
<!--				&lt;!&ndash;医疗器械&ndash;&gt;-->

<!--			</div>-->
	</div>
</template>

<script>

export default {

	data() {
		return {

		}
	},
	methods: {
goToGoodsClass(){
   this.$emit('changeType',12)
},
		goToGoodsDetail(id){
			this.$emit('changeType2',id,13)
		},
		goToArticlesDetail(id){
			this.$emit('changeType1',id,14)
		},
    goTolocaltion(type){

  let height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
  console.log(height)
    if(type==1){
      window.scrollTo(0, 650)
    }
      else if(type==2){
      window.scrollTo(0, 1100)
    }
      else if(type==3){
      window.scrollTo(0, 1800)
    }
      else if(type==4){
      window.scrollTo(0, 2500)
    }
    else if(type==5){
      window.scrollTo(0, 3200)
    }else if(type==6){
      window.scrollTo(0, 0)
    }
    }
	}
}
</script>

<style>

@import '../../common/styles/base.css';
@import '../../common/styles/style.css';
@import '../../common/styles/media_index.css';
@import '../../common/styles/mod.css';
</style>

<style lang="scss">
	.shoppingDetail{
		cursor:pointer;
	}
  .leftGroup{
    background-color:#3A3C83;
    color:white;
    padding:5px 13px 5px 13px;
    margin-top:1px;
  }
  html {
    scroll-behavior: smooth;
}
</style>
